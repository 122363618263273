import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { LaravelResourceResponse } from '../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../../_base-shared/models/Case/Case';
import { CaseCreditor } from '../../../../../../../_base-shared/models/Case/CaseCreditor';
import { PaymentMethod, PaymentMethodType } from '../../../../../../../_base-shared/models/Payment/PaymentMethod';
import { PaymentProcessorType } from '../../../../../../../_base-shared/models/Payment/PaymentProcessor';
import { PaymentTerm } from '../../../../../../../_base-shared/models/Payment/PaymentTerm';
import { PaymentPlanType } from '../../../../../../../_base-shared/models/Product/PaymentPlanType';
import { User } from '../../../../../../../_base-shared/models/User/User';
import { environment } from '../../../../../environments/environment';
import {
  ChangeAmountModalComponent,
} from '../../../../_shared/components/change-amount-modal/change-amount-modal.component';
import {
  ChangeDateModalComponent,
} from '../../../../_shared/components/change-date-modal/change-date-modal.component';
import {
  RequestPaymentModalComponent,
} from '../../../../_shared/components/request-payment-modal/request-payment-modal.component';
import { MainGlobalEventService } from '../../../../_shared/services/main-global-event.service';
import { DistributionService } from '../../../../distribution/distribution.service';
import {
  AdminPaymentHandlerComponent
} from '../../../payment/admin-payment-handler/admin-payment-handler.component';
import { PaymentMethodService } from '../../../payment/payment-method.service';
import { PaymentPlanTypeService } from '../../../payment/payment-plan-type.service';
import { PaymentService } from '../../../payment/payment.service';
import { CaseDocumentService } from '../../case-document.service';
import { saveAs } from 'file-saver';
import { CaseService } from '../../../case/case.service';

@Component({
  selector: 'app-case-client-activity',
  templateUrl: './case-client-activity.component.html',
  styleUrls: ['./case-client-activity.component.scss']
})
export class CaseClientActivityComponent {
  public componentType: 'admin' = 'admin';
  public case: Case;
  @Input() fetchInstalments: EventEmitter<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  public appEnv: string;
  public upcomingInstallments: Array<PaymentTerm> = [];
  public unpaidInstallments                       = [];
  public displayedColumns: Array<string>          = [];
  public installmentsPlanTypeControl              = new UntypedFormControl(['debt_plan'], [Validators.required]);
  public dataSource: MatTableDataSource<PaymentTerm>;
  public dataSourceUnpaid: MatTableDataSource<PaymentTerm>;
  public recordForm: UntypedFormGroup;
  public totalResults: number;
  public showOnlyUnpaid                           = false;
  public sortOrder                                = null;
  public sortBy                                   = null;
  public filteredInstallmentsBalance              = {
    totalPaid:  0,
    totalToPay: 0,
    totalPaidCounter: 0,
    totalDistributed: 0,
  };
  public debtPlanInstallmentsBalance              = {
    totalPaid:  0,
    totalToPay: 0,
  };
  public additionalPlansInstallmentsBalance       = {
    totalPaid:  0,
    totalToPay: 0,
  };
  public paginatorConfig                          = {
    pageIndex: 0,
    pageSize:  10,
    length:    1,
  };
  public now                                      = new Date(new Date().setHours(0, 0, 0, 0));
  public days                                     = [];
  public isLoading                                = 0;
  public authUser: User;
  public paymentMethods: Array<PaymentMethod>;
  public paymentPlanTypes: Array<PaymentPlanType>;
  private forceDefaultProcessor                   = false;
  public selectionPayments                        = new SelectionModel<any>(true, []);
  public selectionPaymentsDisabled                = true;
  public onlyDebtPlanSelected                     = true;
  public isSubmitting: boolean;

  public expandedRow: number;
  public expandedElement: CaseCreditor | null;
  public templateData: Array<any> = [];
  public creditorsMerged: Array<any> = [];

  public authorizedUsers                          = [27, 751562, 751600, 752733];
  //public excludedStatuses: number[] = [11,14,15,19,24,25,26,27,33,36];
  public excludedProductId: number[] = [18];
  public serverResponse: LaravelResourceResponse;
  public runningBalance: number = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private translate: TranslateService,
    private globalEventsService: MainGlobalEventService,
    private paymentService: PaymentService,
    private distributionService: DistributionService,
    private paymentMethodService: PaymentMethodService,
    private paymentPlanTypeService: PaymentPlanTypeService,
    private caseDocumentService: CaseDocumentService,
    private caseService: CaseService,
  ) {
    this.appEnv = environment.APP_ENV;
    this.case=null;
  }


  ngOnInit(): void {
    
    this.route.parent.paramMap.subscribe(params => {
      const caseId = +params.get('id');
      this.isLoading++;
      this.caseService.get(caseId, ['client', 'payment_plans', 'terms', 'creditors', 'public_debts', 'debt_payment_plan', 'secured_creditors', 'unsecured_creditors', 'expense'])
        .pipe(finalize(() => this.isLoading--))
        .subscribe(
          result => {
            this.case = result.data;
            console.log("case infoooo");
            console.log(this.case);
            this.displayedColumns = this.getTableColumns(this.componentType);
            this.fetchInstallments(this.componentType);

            //  Get total amount of paid terms and total amount to pay
            this.case.terms.forEach(term => {
              this.filteredInstallmentsBalance.totalToPay += term.amount;
              this.filteredInstallmentsBalance.totalPaid += term.amount_paid;
              this.filteredInstallmentsBalance.totalPaidCounter += this.filteredInstallmentsBalance.totalPaidCounter;
              if (term.case_plan_id === this.case.debt_payment_plan.id) {
                this.debtPlanInstallmentsBalance.totalToPay += term.amount;
                this.debtPlanInstallmentsBalance.totalPaid += term.amount_paid;
              }
              if (term.case_plan_id !== this.case.debt_payment_plan.id) {
                this.additionalPlansInstallmentsBalance.totalToPay += term.amount;
                this.additionalPlansInstallmentsBalance.totalPaid += term.amount_paid;
              }
            });
        
            /*this.selectionPayments.changed.subscribe(next => {
              this.checkIfDisabled(next);
            });*/
        
            //better to calculate if phase1 or 2 using variables than calling from the template
            //this.templateData.push(this.viewCalculations());
          },
          error => this.serverResponse = error.error,
        )
    });

    this.globalEventsService.authUser$.subscribe(user => {
      this.authUser         = user;
      //this.fetchPaymentPlanTypes(this.componentType);
    });

    //  Generate days array
    for (let i = 1; i <= 31; i++) {
      this.days.push(i);
    }

  }

  private fetchInstallments(componentType: 'admin') {
    const data = {
      per_page:        this.paginatorConfig.pageSize,
      page:            this.paginatorConfig.pageIndex + 1,
      case_id:         this.case.id,
      select_all:      1,
      order:           this.sortOrder || 'asc',
      sort_by:         this.sortBy || 'term_date',
      plan_type_slugs: this.installmentsPlanTypeControl.value ? this.installmentsPlanTypeControl.value : [],
    };

    const withRelations = [
      'case.client',
      'case.debt_payment_plan',
      'distribution_case_terms',
      //'case.terms',
      'distribution_case_terms.batches',
      'case.payment_status',
      'case_payment_plan.type',
      'case.distribution',
    ];

    const observable    = this.paymentService.indexCaseInstallments(this.case.id, data, withRelations)
    //const observable    = componentType === 'admin' ?
    //  this.paymentService.indexCaseInstallments(this.case.id, data, withRelations) :
    //  this.distributionService.indexInstallments(this.case.uuid, data, withRelations);

    this.isLoading++;
    this.dataSource           = new MatTableDataSource<PaymentTerm>([]);
    this.upcomingInstallments = [];
    observable.pipe(finalize(() => this.isLoading--))
      .subscribe(
        res => this.handleResponse(res),
        error => console.log(error),
      );
  }

  public saveTermId(installment: PaymentTerm) {
    const dialogRef = this.dialog.open(ChangeDateModalComponent, {
      width: '50%',
      data:  {
        term: installment,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fetchInstallments(this.componentType);
      }
    });
  }

  public toggleRow(element: any) {
    element.expanded = !element.expanded;
  }

  public showHideUnpaid($event) {
    this.paginator.firstPage();
    if ($event.checked) {
      this.paginatorConfig.length = this.unpaidInstallments.length;
    } else {
      this.paginatorConfig.length = this.upcomingInstallments.length;
    }
    this.showOnlyUnpaid = $event.checked;
  }

  public sortData(sort) {
    this.sortOrder = sort.direction !== '' ? sort.direction : null;
    this.sortBy    = sort.direction !== '' ? sort.active : null;

    this.fetchInstallments(this.componentType);
  }

  public openChangeAmountModal(installment: PaymentTerm) {
    const dialogRef = this.dialog.open(ChangeAmountModalComponent, {
      width: '50%',
      data:  {
        term: installment,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fetchInstallments(this.componentType);
      }
    });
  }

  public requestPaymentDialog(paymentMethodType: PaymentMethodType, paymentProcessorType: PaymentProcessorType): void {
    const nextTerm       = this.upcomingInstallments.find(t => t.amount > t.amount_paid);
    let maxPayableAmount = 0;
    this.upcomingInstallments.forEach(term => {
      maxPayableAmount += term.amount - term.amount_paid;
    });

    maxPayableAmount = +maxPayableAmount.toFixed(2);

    if (nextTerm) {
      const dialogRef = this.dialog.open(RequestPaymentModalComponent, {
        width:        '50%',
        minHeight:    200,
        disableClose: true,
        autoFocus:    false,
        data:         {
          caseId:                this.case.id,
          paymentMethodType,
          paymentProcessorType,
          amount:                (nextTerm.amount - nextTerm.amount_paid).toFixed(2),
          maxPayableAmount,
          forceDefaultProcessor: this.forceDefaultProcessor,
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.success) {
            this.toastr.success(this.translate.instant(
              'CASES.single.request-payment-success'), this.translate.instant('SHARED.success'),
            );
          } else {
            this.toastr.error(this.translate.instant(
              'CASES.single.request-payment-error'), this.translate.instant('SHARED.error'),
            );
          }
        }
      });
    } else {
      this.toastr.error('No installment available');
    }
  }

  public openCardModal(): void {
    const nextTerm       = this.upcomingInstallments.find(t => t.amount > t.amount_paid);
    const chargeAmount   = nextTerm ? (nextTerm.amount - nextTerm.amount_paid).toFixed(2) : 0;
    let maxPayableAmount = 0;
    this.upcomingInstallments.forEach(term => {
      maxPayableAmount += term.amount - term.amount_paid;
    });

    if (nextTerm) {
      const dialogRef = this.dialog.open(AdminPaymentHandlerComponent, {
        width:        '60%',
        minHeight:    200,
        disableClose: false,
        panelClass:   ['custom-mat-dialog', 'scrollable-mat-dialog'],
        autoFocus:    false,
        data:         {
          caseId:          this.case.id,
          clientRole:      'client',
          type:            'moto',
          amount:          chargeAmount,
          editableAmount:  true,
          maxPayableAmount,
          paymentPlanType: this.forceDefaultProcessor ? 'additional_plans' : 'debt_plan',
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result.reFetch) {
          this.fetchInstallments(this.componentType);
          this.fetchInstalments.emit();
          this.displayDistributionWarningModal();
        }
      });
    }
  }

  public buildForm() {
    const defaultPaymentMethod = this.paymentMethods.find(m => m.slug === 'sepa');
    const defaultIncomeAccount = defaultPaymentMethod.billing_bank_accounts.find(account => account.default === true);
    const defaultPlanId        = this.paymentPlanTypes.find(planType => planType.slug === 'debt_plan');

    this.recordForm = this.fb.group({
      case_id:           this.case.id,
      payment_plan_id:   defaultPlanId?.id ? defaultPlanId.id : null,
      note:              '',
      payment_date:      [new Date()],
      payment_method_id: [defaultPaymentMethod.id],
      payment_method:    [defaultPaymentMethod.slug],
      income_account_id: [defaultIncomeAccount.id, Validators.required],
      split_type:        ['custom', null],
      amount:            [0, Validators.required],
      amount_paid:       [null, Validators.required],
      phase_one_amount:  [0, null],
      phase_two_amount:  [0, null],
      phase_one_paid:    [
        this.upcomingInstallments.filter(
          term => (term.type === null || term.type === 'phase_one' || term.type === 'deferred_one') && term.amount >
            term.amount_paid).length === 0, null],
      phase_two_paid:    [
        this.upcomingInstallments.filter(
          term => (term.type === 'phase_two' || term.type === 'deferred_two') && term.amount >
            term.amount_paid).length === 0, null],
      terms:             this.fb.array([]),
      terms_phase_one:   this.fb.array(
        this.upcomingInstallments.filter(
          term => term.type === null || term.type === 'phase_one' || term.type === 'deferred_one'),
      ),
      terms_phase_two:   this.fb.array(this.upcomingInstallments.filter(term => term.type === 'phase_two')),
    });
    this.addInstalments();
    this.calculateAmountPaid();
  }

  addInstalments() {
    const instalments = this.recordForm.get('terms') as UntypedFormArray;
    this.upcomingInstallments.forEach(term => {
      instalments.push(
        this.fb.group({
          id:              term.id,
          term_date:       term.term_date,
          date_paid:       term.date_paid,
          type:            term.type,
          name:            term.name,
          amount:          term.amount,
          amount_paid:     0,
          old_amount_paid: term.amount_paid,
        }),
      );
    });
  }

  calculateAmountPaid() {
    let phaseOneAmount = 0;
    let phaseTwoAmount = 0;
    let amountPaid     = 0;
    let amountPayable  = 0;
    this.upcomingInstallments.forEach(term => {
      amountPayable += term.amount - term.amount_paid;
      amountPaid += term.amount_paid;
      if (term.type === null || term.type === 'phase_one' || term.type === 'deferred_one') {
        phaseOneAmount += term.amount - term.amount_paid;
      } else if (term.type === 'phase_two' || term.type === 'deferred_two') {
        phaseTwoAmount += term.amount - term.amount_paid;
      }
    });
    amountPayable = +amountPayable.toFixed(2);
    this.recordForm.get('phase_one_amount').setValue(phaseOneAmount);
    this.recordForm.get('phase_two_amount').setValue(phaseTwoAmount);
    this.recordForm.get('amount_paid').setValue(amountPaid);
    this.recordForm.get('amount').setValidators([Validators.required, Validators.max(Math.min(amountPayable, 4000))]);
  }

  public getDistributionSchedule(term, previousDistributionDate = null) {

    if (term.type === "phase_one") {
      return null;
    }
  
    const currentDate = new Date();
    const termDate = new Date(term.term_date);
    const datePaid = term.date_paid ? new Date(term.date_paid) : null;

    const currentNextMont = new Date();
    currentNextMont.setMonth(currentNextMont.getMonth() + 1);
  
    // Set distribution to the 15th of the month following termDate
    let distributionDate = new Date(termDate.getFullYear(), termDate.getMonth() + 1, 15);
  
    // If there's a previous distribution date (due to an unpaid installment), use it
    if (previousDistributionDate) {
      const previousDate = new Date(previousDistributionDate);
  
      // Only update if the previous distribution date is not in the same month
      if (previousDate.getMonth() !== termDate.getMonth()) {
        distributionDate = new Date(previousDate.getFullYear(), previousDate.getMonth() + 1, 15);
  
        // If no payment is made, return the calculated distribution date
        if (!datePaid) {
          return distributionDate;
        }
  
        // If a payment is made, adjust the distribution based on the payment date
        distributionDate = new Date(datePaid.getFullYear(), datePaid.getMonth() + 1, 15);
      }
    }
  
    // Handle the case where date_paid is between the 1st and 5th of the next month
    const nextMonth = new Date(distributionDate.getFullYear(), distributionDate.getMonth(), 1);
    const fifthOfNextMonth = new Date(distributionDate.getFullYear(), distributionDate.getMonth(), 7); //requested change to 7 temporarly
  
    // Payment after the 5th of the next month -> shift distribution date one month further
    if (datePaid && datePaid > fifthOfNextMonth) {
      distributionDate = new Date(distributionDate.getFullYear(), distributionDate.getMonth() + 1, 15);
      return distributionDate;
    }
  
    // Payment is made before or on the 5th of the next month -> keep the next month's 15th
    if (datePaid && datePaid >= nextMonth && datePaid <= fifthOfNextMonth) {
      return distributionDate;
    }
  
    // No payment made, return the distribution date based on the current date or previous date
    if (!datePaid) {
      distributionDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 15);
      if (previousDistributionDate){
        const previousDate = new Date(previousDistributionDate);
        if (previousDate.getTime() >= distributionDate.getTime()){
          distributionDate = new Date(previousDate.getFullYear(), previousDate.getMonth() +1, 15);
        }
      }
      return distributionDate;
    }

    //when previous is larger than current and payment is done between 1-5 next month
    if(datePaid && previousDistributionDate && previousDistributionDate > currentNextMont){
      //const previousDate = new Date(previousDistributionDate);
      const fifthOfNextMonth = new Date(datePaid.getFullYear(), datePaid.getMonth(), 7); //requested change to 7 temporarly

      if (datePaid <= fifthOfNextMonth) {
        distributionDate = new Date(datePaid.getFullYear(), datePaid.getMonth(), 15);
      }else{
        distributionDate = new Date(datePaid.getFullYear(), datePaid.getMonth() +1, 15);
      }
    }
  
    return distributionDate;
  }

  public handleResponse(response: LaravelResourceResponse) {
    //  Get total amount of paid terms and total amount to pay
    this.filteredInstallmentsBalance.totalToPay = 0;
    this.filteredInstallmentsBalance.totalPaid  = 0;
    this.filteredInstallmentsBalance.totalPaidCounter = 0;
    this.filteredInstallmentsBalance.totalDistributed = 0;

    let runningBalance = 0;
    const monthlyFee = 35;
    
    response.data.forEach(term => {
      term.notes = "";
      term.monthly_fees = 0;
      term.cred_settlement = 0;
      term.negotiation_fees = 0;
      term.balance_of_funds = 0;

      this.filteredInstallmentsBalance.totalToPay += term.amount;
      this.filteredInstallmentsBalance.totalPaid += term.amount_paid;
      this.filteredInstallmentsBalance.totalPaidCounter += this.filteredInstallmentsBalance.totalPaidCounter;

      if (!term?.type) {
        term.monthly_fees = "";
        term.balance_of_funds = "";
        term.negotiation_fees = term.amount;
        const paymentPlansArray = Array.isArray(this.case.payment_plans) ? this.case.payment_plans : [];
        const paymentPlan = paymentPlansArray.find(plan => plan.id === term.case_plan_id);
        if (paymentPlan?.creditor_id) {
          const creditor = this.case.creditors.find(cred => cred.pivot.id === paymentPlan.creditor_id);
          term.notes = creditor ? creditor.name : 'Unknown Creditor';
          term.cred_settlement = creditor.pivot.negotiated_amount;
        } else {
          term.notes = 'No Creditor Found';
        }
      } else {
        if (term.type == 'phase_one'){
          term.notes = 'Initial Fee';
          term.monthly_fees = term.amount;
        }
        if (term.type == 'phase_two'){
          term.monthly_fees = monthlyFee;
        }
      }

      runningBalance += term.amount - term.monthly_fees;
      if (!term?.type) {
        runningBalance -= term.cred_settlement;
        term.balance_of_funds = runningBalance;
      }else if (term.type == 'phase_two'){
        term.balance_of_funds = runningBalance;
      }
      
      /*term.distribution_case_terms.forEach(dist => {

        const isTermInDistribution = term.case.distribution.some((caseDist: any) => 
            caseDist.distribution_batch_id === dist.batches.id
        );

        if (!isTermInDistribution) {
            console.log(`Batch ID ${dist.batches.id} is not in term.case.distribution`);
            return;
        }
        
        this.filteredInstallmentsBalance.totalDistributed += dist.amount_distributed;
        totalTermDistribution += dist.amount_distributed;
        if (dist.disbursed){
          totalTermDisburse += dist.amount_distributed;
        }
        if (term.id === dist.term_id && 
            dist.distributed_at != null && 
            dist.distributed_at !== undefined 
            && dist.distributed_at !== '') {
              term_distributed_at = dist.distributed_at;
        }
        if (term.id === dist.term_id && 
          dist.disbursed_at != null && 
          dist.disbursed_at !== undefined 
          && dist.disbursed_at !== '') {
            term_disbursed_at = dist.disbursed_at;
        }
      });
      term.total_distributed_amount = totalTermDistribution;
      term.total_disbursed_amount = totalTermDisburse;*/
      //term.distribution_schedule = this.getDistributionSchedule(term, previousDistributionDate);
      //previousDistributionDate = term.distribution_schedule;
      //term.distributed_at = term_distributed_at;
      //term.disbursed_at = term_disbursed_at;
      //term.offer_to_creditors_total = Number(this.case.expense.offer_to_creditors_total);
      //term.distribution_fee = Number(this.case.expense.distribution_fee);

    });

    const onlyUnpaid = response.data.filter(obj => obj.amount !== obj.amount_paid);

    this.totalResults               = response.data.total;
    this.upcomingInstallments       = response.data;
    this.unpaidInstallments         = onlyUnpaid;
    this.dataSource                 = new MatTableDataSource<PaymentTerm>(response.data);
    this.dataSourceUnpaid           = new MatTableDataSource<PaymentTerm>(onlyUnpaid);
    this.dataSource.paginator       = this.paginator;
    this.dataSource.sort            = this.sort;
    this.dataSourceUnpaid.paginator = this.paginator;
    this.dataSourceUnpaid.sort      = this.sort;
    this.paginatorConfig.length     = response.data.length;
  }

  public getPercent(paid, amount) {
    if ((paid / amount) * 100) {
      return ((paid / amount) * 100).toFixed(2);
    } else {
      return 0;
    }
  }

  deleteAndRebuild() {
    this.isLoading++;
    this.dataSource = new MatTableDataSource<PaymentTerm>([]);

    this.paymentService.deleteAllPayments(this.case.id) //  Delete all installments
      .subscribe(
        value => {
          this.paymentService.rebuildInstallmentPlan(this.case.id)  //  Rebuild installments
            .pipe(finalize(() => this.isLoading--))
            .subscribe(
              res => {
                this.fetchInstallments(this.componentType);
                this.toastr.success(this.translate.instant('CASES.single.rebuild-plan-note-success'));
              },
              error => {
                this.toastr.error(error.message, this.translate.instant('SHARED.error'));
              },
            );
        },
        error => {
          this.toastr.error(error.message, this.translate.instant('SHARED.error'));
        },
      );
  }

  rebuild() {
    this.isLoading++;
    this.dataSource = new MatTableDataSource<PaymentTerm>([]);

    this.paymentService.rebuildInstallmentPlan(this.case.id)  //  Rebuild installments
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        res => {
          this.fetchInstallments(this.componentType);
          this.toastr.success(this.translate.instant('CASES.single.rebuild-plan-note-success'));
        },
        error => {
          this.toastr.error(error.message, this.translate.instant('SHARED.error'));
        },
      );
  }

  rebuildManual(data) {
    this.isLoading++;
    this.dataSource = new MatTableDataSource<PaymentTerm>([]);

    this.paymentService.rebuildInstallmentPlanManual(data)  //  Rebuild installments
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        res => {
          this.fetchInstallments(this.componentType);
          this.toastr.success(this.translate.instant('CASES.single.rebuild-plan-note-success'));
        },
        error => {
          this.toastr.error(error.message, this.translate.instant('SHARED.error'));
        },
      );
  }

  deleteAllPayments() {
    Swal.fire({
      title:              this.translate.instant('CASES.single.remittance-request-text'),
      text:               this.translate.instant('CASES.single.remittance-request-warning'),
      icon:               'warning',
      showCancelButton:   true,
      cancelButtonText:   this.translate.instant('SHARED.no'),
      confirmButtonText:  this.translate.instant('SHARED.yes'),
      confirmButtonColor: '#886ab5',
    }).then(res => {
      if (res.isConfirmed) {
        this.isLoading++;
        this.dataSource = new MatTableDataSource<PaymentTerm>([]);

        this.paymentService.deleteAllPayments(this.case.id).pipe(finalize(() => this.isLoading--))
          .subscribe(
            value => {
              this.fetchInstallments(this.componentType);
              this.toastr.success(this.translate.instant('CASES.single.remittance-request-success'));
            },
            error => {
              this.toastr.error(this.translate.instant('CASES.single.remittance-request-error'));
            },
          );
      }
    });
  }

  private fetchPaymentMethods(componentType: 'admin'): void {
    this.isLoading++;
    this.paymentMethodService.index({}, ['billing_bank_accounts']).pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        this.paymentMethods = result.data;
        this.buildForm();
      });
  }

  private fetchPaymentPlanTypes(componentType: 'admin') {
    this.isLoading++;
    this.paymentPlanTypeService.index().pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        let data              = result.data.filter(planType => ! ['claim_fee', 'asnef_fee', 'annual_review_fee'].includes(planType.slug));
        this.paymentPlanTypes = componentType === 'admin' ?
          data :
          data.filter(planType => planType.default === true);
        this.fetchPaymentMethods(this.componentType);
      });
  }

  changePaymentMethod($event): void {
    this.isLoading++;
    const data = {payment_method: $event.target.value};
    this.paymentMethodService.updateCasePaymentMethod(this.case.id, data).pipe()
      .subscribe(res => {
          this.isLoading--;
          this.toastr.success('', this.translate.instant('SHARED.success'),
          );
        },
        error => {
          this.isLoading--;
          this.toastr.error('', this.translate.instant('SHARED.error'));
        },
      );
  }

  paymentMethodIsSelected($paymentMethodId: number): boolean {
    return $paymentMethodId === this.case?.debt_payment_plan?.payment_method_id;
  }

  displayDistributionWarningModal(): void {
    Swal.fire({
      text:               this.translate.instant('DISTRIBUTION.modals.confirm-payment'),
      icon:               'warning',
      showCancelButton:   false,
      confirmButtonText:  'OK',
      confirmButtonColor: '#4267b2',
    });
  }

  public updateComponentType(type: 'admin') {
    this.componentType    = type;
    this.displayedColumns = this.getTableColumns(type);
    this.fetchInstallments(type);
  }

  private getTableColumns(type: 'distribution' | 'admin'): Array<string> {
    const columns = [
      //'select',
      //'client-name',
      //'email',
      //'name',
      'next-payment',
      'date-paid',
      'amount',
      'cred-settlement',
      'monthly-fees',
      'negotiation-fees',
      'balance-of-funds',
      'notes'
    ];
    //if (type === 'admin') {
    //  columns.push('actions');
    //}
    
    return columns;
  }

  public deleteInstallment(termId: number) {
    Swal.fire({
      title:              this.translate.instant('SHARED.warning'),
      text:               this.translate.instant('SHARED.action.delete_confirmation',
        {model: this.translate.instant('INSTALLMENTS.model_name.singular')}),
      icon:               'warning',
      showCancelButton:   true,
      confirmButtonText:  this.translate.instant('SHARED.delete'),
      cancelButtonText:   this.translate.instant('SHARED.cancel'),
      confirmButtonColor: '#886ab5',
    }).then(res => {
      if (res.isConfirmed) {
        this.isLoading++;
        this.dataSource = new MatTableDataSource<PaymentTerm>([]);

        this.paymentService.deleteCaseInstallment(this.case.id, termId).pipe(finalize(() => this.isLoading--))
          .subscribe(
            value => {
              this.fetchInstallments(this.componentType);
              this.toastr.success(this.translate.instant('SHARED.submit_result.delete.success', {
                model: this.translate.instant('INSTALLMENTS.model_name.singular'),
              }));
            },
            error => {
              this.toastr.error(this.translate.instant('SHARED.submit_result.delete.error', {
                model: this.translate.instant('INSTALLMENTS.model_name.singular'),
              }));
            },
          );
      }
    });
  }

  public markAsPromo(installment: PaymentTerm) {
    this.isLoading++;
    this.paymentService.caseInstallmentMarkAsPromo(this.case.id, installment.id).pipe(finalize(() => this.isLoading--))
      .subscribe(
        result => {
          this.toastr.success(this.translate.instant('SHARED.submit_result.update.success', {
            model: this.translate.instant('INSTALLMENTS.model_name.singular'),
          }));
        },
        error => {
          this.toastr.error(this.translate.instant('SHARED.submit_result.update.error', {
            model: this.translate.instant('INSTALLMENTS.model_name.singular'),
          }));
        }
      );
  }

  public isPhaseTwo(){
    const phaseTwoStatusIds = [335,507];
    //const enabledRoles = environment.ALLOW_CASES_ROLES_IDS;
    
    if (
        this.userIsAMami() &&
        this.case.status_id === phaseTwoStatusIds[1] &&
        this.case.distribution_case_terms.length > 0)
    {
      return true;
    }
    if (this.case?.status_id !== undefined && this.case?.status_id !== null){
      return this.case.status_id === phaseTwoStatusIds[0];
    }
    return false;
   
  }

  public userIsAMami(): boolean {
    return environment.DISTRIBUTION_USER_IDS.includes(this.authUser.id);
  }

  public isPhaseOne(){
    const phaseTwoStatusIds = [411, 383, 394, 113];
    if(this.case?.status_id !== undefined && this.case?.status_id !== null){
      return phaseTwoStatusIds.includes(this.case.status_id);
    }
    return false;
  }

  public getMonthsRemaining(){
    //console.log("months remaining");
    //console.log(this.case.terms);
    //console.log(this.case.terms.length);
    let totalMonths : number = this.case.terms.length;
    this.case.terms.forEach((item, index) => {
      console.log(`Item at index ${index}:`, item);
      if (item.amount_paid > 0){
        totalMonths--;
      }
    });
    return totalMonths;
  }

  public viewCalculations(){
    //months pending to pay
    let totalMonthsToPay : number = this.case.terms.length;
    let fee = this.case.expense.distribution_fee;
    let feesPaid = 0;
    let totalMonthsFullyPaid = 0;
    let totalDebtAmount = 0;
    let totalFinalPayments= 0;
    let totalCreditorsOffer = 0;
    this.case.terms.forEach((item, index) => {
      
      //console.log(`Item at index ${index}:`, item);
      if (item.amount_paid > 0){
        totalMonthsToPay--;
        feesPaid += item.amount_paid;
      }
      if (item.amount_paid != null && item.amount_paid > this.case.expense.distribution_fee){
        //console.log("Fully paid");
        //console.log(item.amount_paid);
        //console.log(fee);
        totalMonthsFullyPaid++;
      }
    });

    this.case.creditors.forEach((item, index) => {
      if (item.pivot.debt_amount > 0){
        totalDebtAmount += Number(item.pivot.debt_amount);
      }

      //Ehem
      if (item.pivot.offer_to_creditor > 0){
        totalCreditorsOffer += item.pivot.offer_to_creditor;
      }

      if (item.pivot.final_payment > 0){
        totalFinalPayments += item.pivot.final_payment;
      }
    });

    return { DmTotalMonthsPending: totalMonthsToPay,
        DMfeePaid: feesPaid,
        DMMonthsFeeFullyPaid: totalMonthsFullyPaid,
        DMTotalDebtAmount: totalDebtAmount,
        totalCreditorsOffer,
        totalFinalPayments //,
        //DMbalance: balance
      };
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(selection, dataSource) {
    const numSelected = selection.selected.length;
    const numRows     = dataSource.data.length;

    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(selection, dataSource) {
    if (this.isAllSelected(selection, dataSource)) {
      selection.clear();
      return;
    }

    selection.select(...dataSource.data);
  }

  deletePayment($event, selectionPayment) {
    $event.preventDefault();
    const installment_ids = [];
    selectionPayment._selected.forEach(selected => installment_ids.push(selected.id));
    this.paymentService.deletePayments(this.case.id, installment_ids).pipe(finalize(() => this.isLoading--))
      .subscribe(
        value => {
          this.toastr.success(this.translate.instant('SHARED.item-deleted'));
        },
        error => {
          this.toastr.error(this.translate.instant('SHARED.went-wrong'));
        },
      );
  }

  generateRemittanceRequest(): void {
    this.paymentService.generateRemittanceRequest(this.case.id)
      .subscribe(res => {
        this.toastr.success(this.translate.instant('SHARED.success'));
      }, err => {
        this.toastr.error(this.translate.instant('SHARED.went-wrong'));
      });
  }

  public downloadClientInstallment(): void {
    const data = {
      document_type_slug: 'client-installment-list'
    };
    this.isSubmitting = true;
    this.caseDocumentService.generateDocument(this.case.id, data)
      .pipe(finalize(() => this.isSubmitting = false))
      .subscribe(result => {
        if (result.type === 4) {
          const fileName = 'client-installment-list_' + DateTime.local().toFormat('yyyy-LL-dd_HH-mm') + '.' + 'pdf';
          saveAs(result.body, fileName);
          this.toastr.success('Downloaded document');
        }
      }, err => {
        this.toastr.error('Failed to generate document');
      });
  }

  public sendPaymentPlan(): void {
    this.caseDocumentService.sendClientInstallmentList(this.case.id).subscribe(res => {
      this.toastr.success(this.translate.instant('SHARED.success'));
    }, err => {
      this.toastr.error(this.translate.instant('SHARED.went-wrong'));
    });
  }

  public isElementRestricted(){
    
    const enabledRoles = environment.ALLOW_CASES_ROLES_IDS;
    const enabledStatusId = environment.BLOCK_CASES_STATUS_IDS;
    const enabledDepartmentsId = environment.ALLOW_CASES_DEPARTMENTS_IDS;

    const isDepartmentAllowed = this.authUser.departments.some(department => 
      enabledDepartmentsId.includes(department.id)
    );

    if ( 
      !enabledStatusId.includes(this.case.status_id) || 
       enabledRoles.includes(this.authUser.role_id) ||
       isDepartmentAllowed
    ){
      return true;
    }
    return false;
  }

  hasMatchingCreditor(term: any): boolean {
    return this.case.unsecured_creditors.some(
      (creditor: any) => term.batches.name === creditor.name
    );
  }

  hasDistributionCaseTerms(): boolean {
    return this.case?.distribution_case_terms?.length > 0;
  }

  /*getUniqueCreditors(creditors: any[]): any[] {
    return creditors.filter((item, index, self) =>
      index === self.findIndex(t => t.id === item.id)
    );
  }*/
  
  isUnfifyePlan(element: any, creditor: any): boolean {
    const name = element.name;
    const parts = name.split(' ');
    const instalmentId = parts[parts.length - 1];
    const instalmentNumber = Number(instalmentId);
  
    if (creditor.name !== 'UNIFYE' && element.name.includes('Phase 2') && !isNaN(instalmentNumber)) {
      return Number(creditor.pivot.terms) >= instalmentNumber;
    }
    return true;
  }

  isBatchIncluded(term: any, element: any): boolean {
    // Ensure term.batches.id is treated as an array
    const batchIds = Array.isArray(term.batches?.id) ? term.batches.id : [term.batches?.id];
  
    // Ensure element.case.distribution is an array and extract the distribution_batch_id values
    const distributionBatchIds = Array.isArray(element.case.distribution)
      ? element.case.distribution.map((dist: any) => dist.distribution_batch_id)
      : [element.case.distribution?.distribution_batch_id];
  
    // Check if any batchIds exist in distributionBatchIds
    return batchIds.some((batchId: any) => distributionBatchIds.includes(batchId));
  }

  isMatchingFinalPhase(name: string, terms: number): boolean {
    const match = name.match(/Phase 2 - (\d+)/);
    if (match) {
      const phaseNumber = parseInt(match[1], 10); 
      return phaseNumber === terms;
    }
    return false; 
  }

  calculateSumsByPhase(element: any, creditors: any[]): { matchingPhaseSum: number } {
    let matchingPhaseSum = element.amount;
    let elementName = element.name;
    const parts = elementName.split(' ');
    const instalmentId = parts[parts.length - 1];
    const instalmentNumber = Number(instalmentId);
  
    creditors.forEach(creditor => {
      if (!creditor.pivot) return;
      if (this.excludedProductId.includes(creditor.pivot.product_id)) return;
    
      if (this.isMatchingFinalPhase(elementName, creditor.pivot.terms)) {
        if (creditor.pivot.final_payment) {
          matchingPhaseSum -= creditor.pivot.final_payment;
        }
      } else {
        if (creditor.pivot.offer_to_creditor){
          if (instalmentNumber < creditor.pivot.terms){
            matchingPhaseSum -= creditor.pivot.offer_to_creditor;
          }else{
            if (creditor.name=="UNIFYE"){
              matchingPhaseSum -= creditor.pivot.offer_to_creditor;
            }
          }
        }
      }
    });
    return { matchingPhaseSum };
  }

  getUniqueCreditors(term) {
    const displayed = new Set();
    return this.case.unsecured_creditors.filter(creditor => {
      if (term.batches.name === creditor.name && !this.excludedProductId.includes(creditor.pivot.product_id)) {
        if (!displayed.has(creditor.name)) {
          displayed.add(creditor.name);
          return true;
        }
      }
      return false;
    });
  }

  hasMismatch(term): boolean {
    
    // Get all creditors (NOT unique ones) that match the term's batch name
    const matchingCreditors = this.case.unsecured_creditors.filter(creditor => 
      creditor.name === term.batches.name
    );
 
    // Check if at least one has a correct offer_to_creditor or final_payment
    const hasMatch = matchingCreditors.some(creditor => {
      return creditor.pivot.offer_to_creditor === term.amount_to_distribute ||
             creditor.pivot.final_payment === term.amount_to_distribute;
    });
  
    return !hasMatch; // Return true if no matches found (show warning), false otherwise
  }
}
