import { DistributionBatchStatus } from '../Distribution/DistributionBatch';

export class CaseListFilter {
  search?: string;
  start_date?: string | Date;
  end_date?: string | Date;
  all_active?: boolean | 0 | 1;
  status_date_type?: 'sign_up' | 'change_date';
  amount_paid_greater_then?: number;
  percentage_reduction_less_then?: number;
  percentage_reduction_greater_then?: number;
  amount_paid_less_then?: number;
  debt_level_greater_then?: number;
  debt_level_less_then?: number;
  outstanding_balance?: number;
  last_action?: number; // eg. more than X days since last_action_at
  has_property?: number | 1 | 2;
  has_sold_debt?: boolean | 1 | 2;
  has_settlement_funds?: boolean | 1 | 2;
  has_microlenders?: boolean | 0 | 1;
  seventy_percent_microlenders?: boolean | 0 | 1;
  seventy_percent_reduction?: boolean | 0 | 1;
  dm_percent_accepted_less?: number;
  dm_percent_accepted_greater?: number;
  has_pending_docs?: boolean | 0 | 1;
  has_public_debt?: boolean | 0 | 1;
  region?: string;
  city?: string;
  product?: string;
  affiliates?: Array<number>;
  creditors?: Array<number>;
  statuses?: Array<number>;
  payment_statuses?: Array<number>;
  case_statuses?: Array<number>;
  call_statuses?: Array<number>;
  case_creditor_statuses?: Array<number>;
  case_invoice_status_ids?: Array<number>;
  debt_status?: Array<number>;
  select_all?: boolean | 0 | 1; // paginated or return all
  only_count?: boolean | 0 | 1;
  only_related?: boolean | 0 | 1;
  product_group?: Array<number>;
  packagers?: Array<number>;
  packager_statuses?: Array<number>;
  // Case Roles
  user_department_assignments?: Array<number>;
  verifier?: Array<number>;
  'legal-advisor'?: Array<number>;
  'case-manager'?: Array<number>;
  'customer-contact'?: Array<number>;
  'creditor-negotiator'?: Array<number>;
  'notary-manager'?: Array<number>;
  lawyer?: Array<number>;
  'draft-manager'?: Array<number>;
  'collections-agent'?: Array<number>;
  // Case Entities
  notaries?: Array<number>;
  administrators?: Array<number>;
  courts?: Array<number>;
  solicitors?: Array<number>;
  notary_appointed?: boolean | 0 | 1;
  // Extra
  days_no_contact?: number;
  days_no_contact_channels?: Array<string>;
  client_allow_marketing?: boolean | 0 | 1;
  has_distribution?: boolean | 0 | 1;
  case_distribution_status?: 'in_distribution' | 'not_in_distribution_and_nonviable' | 'nonviable';
  flag_distribution_nonviable?: boolean | 0 | 1;
  distribution_batch_statuses?: Array<DistributionBatchStatus>;
  distribution_batch_ids?: Array<number> | null;
  team_ids?: Array<number> | null;
  affiliate_user_ids?: Array<number>;
  new_partner?: boolean | 0 | 1;
  product_group_slugs?: Array<string>;
  product_ids?: Array<number>;
  digital_signature?: 'digital_certificate' | 'clave_pin' | 'none';
  has_redsys_has_no_cashflows_token?: boolean | 0 | 1;

  // Sort
  order?: 'asc' | 'desc';
  sort_by?: 'id' | 'ref_number' | 'first_name' | 'last_name' | 'joint_application' | 'created_at' | 'last_action_at' | 'status' |
            'payment_status' | 'affiliate' | 'product' | 'debt_amount' | 'pending_files_count' | 'call_status';

  // Paginate
  per_page?: number;
  page?: number;

  // Extra
  cases?: Array<number>; // DEPRECATED, use `case_ids` instead
  case_ids?: Array<number>;
  terms?: Array<number>;
  expected_count?: number;
  activity_status?: string;
  page_type?: string;
  docStatusOptions?: Array<any>;
  doc_statuses?: any;
  has_imported_creditors?: boolean | 0 | 1;
  days_since_last_neg_note?: number;

  // optional
  number_of_creditors?: number;
  amount_paid?: number;
  is_from_case_creditor?: boolean | 0 | 1;

  // creditor specific things
  not_statuses?: Array<number>;
  cc_not_statuses?: Array<number>;

  // Action required specific things
  case_status_not_in?: Array<number>;
  case_status_in?: Array<number>;
  case_payment_statuses?: Array<number>;
  settlement_balance_greater_than_cred_debt?: boolean | 0 | 1;
}
