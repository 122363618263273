<div class="container">
  <form *ngIf="formReady" class="row" [formGroup]="form" (ngSubmit)="submit()">
    <div class="col-6">
      <h1>{{"CONFIG.misc.header" | translate}}</h1>
    </div>
    <div class="col-6">
      <div class="d-flex justify-content-end">
        <app-spinner-btn [loading]="isSubmitting" [name]="'SHARED.save' | translate"></app-spinner-btn>
      </div>
    </div>
    <div class="col-12">
      <div class="card shadow">
        <div class="card-body pt-5">
          <div class="container">
            <div class="row pb-5">
              <div class="col-12">
                <h4>Site Configuration</h4>
              </div>
              <!-- Team monthly target FDS -->
              <div class="col-6">
                <app-input type="number" formControlName="team_monthly_target_fds" [fullWidth]="true"
                          [label]="'CONFIG.misc.team-monthly-target-fds' | translate" [extraLabel]="true">
                </app-input>
              </div>
              <!-- Individual Monthly Target FDS -->
              <div class="col-6">
                <app-input type="number" formControlName="individual_monthly_target_fds" [fullWidth]="true"
                          [label]="'CONFIG.misc.individual-monthly-target-fds' | translate" [extraLabel]="true">
                </app-input>
              </div>
              <!-- APR Rate -->
              <div class="col-6">
                <app-input type="number" formControlName="apr_rate" [fullWidth]="true"
                          [label]="'CONFIG.misc.apr_rate' | translate" [extraLabel]="true">
                </app-input>
              </div>
              <!-- Debt Reduction Fee -->
              <div class="col-6">
                <app-input type="number" formControlName="debt_reduction_fee" [fullWidth]="true"
                          [label]="'CONFIG.misc.debt_reduction_fee' | translate" [extraLabel]="true">
                </app-input>
              </div>
            </div>
            <div class="row pb-5">
              <div class="col-12">
                <h4>Distribution Fees</h4>
              </div>
              <!-- Fixed Distribution Fee -->
              <div class="col-6">
                <app-input type="number" formControlName="fixed_distribution_fee" [fullWidth]="true"
                label="Fixed Distribution Fee" [extraLabel]="true">
                </app-input>
              </div>
              <div class="col-6">
                <!-- Percentage Distribution Fee -->
                <app-input type="number" formControlName="percentage_distribution_fee" [fullWidth]="true"
                          label="Percentage Distribution Fee" [extraLabel]="true">
                </app-input>
              </div>
            </div>
            <div class="row pb-5">
              <div class="col-12">
                <h4>DS Specific options</h4>
              </div>
              <div class="col-6">
                <!-- Default win percentage -->
                <app-input type="number" formControlName="ds_reduction_percent" [fullWidth]="true"
                  label="DS Reduction Percentage" [extraLabel]="true">
                </app-input>
              </div>
              <div class="col-6">
                <!-- Default win percentage -->
                <app-input type="number" formControlName="ds_default_fee_amount" [fullWidth]="true"
                  label="DS Default Fee Amount" [extraLabel]="true">
                </app-input>
              </div>
              <div class="col-6">
                <!-- Default win percentage -->
                <app-input type="number" formControlName="ds_default_fds_reduction" [fullWidth]="true"
                  label="DS Default FDS Reduction" [extraLabel]="true">
                </app-input>
              </div>
            </div>
            <div class="row pb-5">
              <div class="col-12">
                <h4>Percentage Reductions</h4>
              </div>
              <div class="col-6">
                <!-- Percent Reduction Good -->
                <app-input type="number" formControlName="percentage_reduction_good" [fullWidth]="true"
                label="Good Percentage Reduction" [extraLabel]="true">
                </app-input>
                <!-- Percent Reduction Bad -->
                <app-input type="number" formControlName="percentage_reduction_bad" [fullWidth]="true"
                label="Bad Percentage Reduction" [extraLabel]="true">
                </app-input>
              </div>
              <div class="col-6">
                <!-- Percent Reduction Decent -->
                <app-input type="number" formControlName="percentage_reduction_decent" [fullWidth]="true"
                          label="Decent Percentage Reduction" [extraLabel]="true">
                </app-input>
              </div>
            </div>
            <div class="row pb-5">
              <div class="col-12">
                <h4>Auto Mandate Options</h4>
              </div>
              <!-- Minimum amount paid -->
              <div class="col-6">
                <!-- Mandates Amount Paid Threshold -->
                <app-input *ngIf="amountPaid.length" type="select"
                  formControlName="mandates_amount_paid_threshold" [extraLabel]="true" [fullWidth]="true"
                  label="Mandates Amount Paid Threshold" [selectOptions]="amountPaid"
                  [selectLabel]="'label'" [selectValue]="'value'">
                </app-input>
              </div>
              <div class="col-6">
                <!-- place holder for right column -->
              </div>
            </div>
            <div class="row pb-5">
              <div class="col-12">
                <h4>Default Microlender Options</h4>
              </div>
              <div class="col-6">
                <!-- Minimum Offer to Creditors -->
                <app-input type="number" formControlName="minimum_offer_to_creditors" [fullWidth]="true"
                  label="Minimum Offer to Creditor per month" [extraLabel]="true">
                </app-input>
              </div>
              <div class="col-6">
                <!-- Maximum Percentage offer to Creditors -->
                <app-input type="number" formControlName="maximum_percentage_offer_to_creditors" [fullWidth]="true"
                  label="Maximum % offer to Creditors" [extraLabel]="true">
                </app-input>
              </div>
            </div>
            <div class="row pb-5">
              <div class="col-12">
                <h4>Negotiation Options</h4>
              </div>
              <div class="col-6">
                <!-- Default win percentage -->
                <app-input type="number" formControlName="negotiation_fee_percentage" [fullWidth]="true"
                  label="Negotiation Fee Percentage" [extraLabel]="true">
                </app-input>
              </div>
              <div class="col-6">
                <!-- Default win percentage -->
                <app-input type="number" formControlName="default_iva_rate" [fullWidth]="true"
                  label="Default Tax Rate Percentage" [extraLabel]="true">
                </app-input>
              </div>
            </div>
            <div class="row pb-5">
              <div class="col-12">
                <h4>Default Expense Options</h4>
              </div>
              <div class="col-6">
                <!-- Ibi -->
                <app-input type="number" formControlName="expense_default_ibi" [fullWidth]="true"
                  [label]="'CASES.single.ibi' | translate" [extraLabel]="true">
                </app-input>
              </div>
              <div class="col-6">
                <!-- Default win percentage -->
                <app-input type="number" formControlName="expense_default_water" [fullWidth]="true"
                  [label]="'CASES.single.water' | translate" [extraLabel]="true">
                </app-input>
              </div>
              <div class="col-6">
                <!-- Default win percentage -->
                <app-input type="number" formControlName="expense_default_power" [fullWidth]="true"
                  [label]="'CASES.single.electricity' | translate" [extraLabel]="true">
                </app-input>
              </div>
              <div class="col-6">
                <!-- Default win percentage -->
                <app-input type="number" formControlName="expense_default_fuel" [fullWidth]="true"
                  [label]="'CASES.single.fuel' | translate" [extraLabel]="true">
                </app-input>
              </div>
              <div class="col-6">
                <!-- Default win percentage -->
                <app-input type="number" formControlName="expense_default_insurance" [fullWidth]="true"
                  [label]="'CASES.single.car-insurance' | translate" [extraLabel]="true">
                </app-input>
              </div>
              <div class="col-6">
                <!-- Default win percentage -->
                <app-input type="number" formControlName="expense_default_bus" [fullWidth]="true"
                  [label]="'CASES.single.public-transport' | translate" [extraLabel]="true">
                </app-input>
              </div>
              <div class="col-6">
                <!-- Default win percentage -->
                <app-input type="number" formControlName="expense_default_phone" [fullWidth]="true"
                  [label]="'CASES.single.home-phone' | translate" [extraLabel]="true">
                </app-input>
              </div>
              <div class="col-6">
                <!-- Default win percentage -->
                <app-input type="number" formControlName="expense_default_groceries" [fullWidth]="true"
                  [label]="'CASES.single.food' | translate" [extraLabel]="true">
                </app-input>
              </div>
            </div>
            <div class="row pb-5">
              <div class="col-12">
                <h4>Default Lockout Options</h4>
              </div>
              <div class="col-6">
                <!-- Default percentage of microlenders to creditors -->
                <app-input type="number" formControlName="lockout_default_percentage" [fullWidth]="true"
                  label="Default % of Microlenders to Creditors" [extraLabel]="true">
                </app-input>
              </div>
            </div>
            <div class="row pb-5">
              <div class="col-12">
                <h4>Default Creditor Options</h4>
              </div>
              <div class="col-6">
                <!-- Default percentage of microlenders to creditors -->
                <app-input type="number" formControlName="default_monthly_term" [fullWidth]="true"
                  label="Default monthly term for Microlenders" [extraLabel]="true">
                </app-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
