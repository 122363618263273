<div class="container">
  <div class="row">
    <div class="col-8">
      &nbsp;
    </div>
    <div class="col-4 text-right">
      <mat-slide-toggle [(ngModel)]="onlyRelated" (change)="myCases()">
        {{ "CASES.list.filters.only_related.label" | translate }}
      </mat-slide-toggle>
    </div>
  </div>

  <div class="row">
    <div class="col-8"></div>
    <div class="col-4" [formGroup]="form">
      <!-- drop down for departmentCategories -->
      <ng-container *ngFor="let departmentCategory of departmentCategories">
        <ng-container *ngFor="let department of departmentCategory.departments">
          <div class=" col-lg" *ngIf="department.active">
            <app-input type="select" *ngIf="form" formControlName="user_id" appearance="standard"
                        [label]="department.name" [fullWidth]="true" searchable="true"
                        [selectOptions]="department.users" [selectLabel]="'name'" [selectValue]="'id'">
            </app-input>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
  
  <!-- Loader -->
  <div *ngIf="dsLoadingCases" class="text-center" style="padding: 50px">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div *ngIf="!dsLoadingCases">
    <div>
      <h3>{{ 'SHARED.action-required.ds_cases' | translate }}</h3>
      <table mat-table [dataSource]="dataBalanceDebt">
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <!-- Ref Number -->
        <ng-container matColumnDef="ref_number">
          <th mat-header-cell *matHeaderCellDef>{{ "CASES.model.ref_number" | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <a class="pr-3" (click)="$event.stopPropagation()" [routerLink]="['/cases', element.id, 'general']">
              {{ element.ref_number }}
            </a>
          </td>
        </ng-container>
        <!-- Last Name -->
        <ng-container matColumnDef="negotiator">
          <th mat-header-cell *matHeaderCellDef>{{ "SHARED.model.name" | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element.department_with_user[0]?.name ?? 'N/A' }}</td>
        </ng-container>
        <!-- Case Status -->
        <ng-container matColumnDef="case_status">
          <th mat-header-cell *matHeaderCellDef>{{ "CASES.case-status" | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <div>
              <span class="badge badge-primary">{{ element.status?.name }}</span>
            </div>
            <div>
              <span class="badge badge-primary-inverted">{{ element.payment_status?.name }}</span>
            </div>
          </td>
        </ng-container>
        <!-- Amount of creditors -->
        <ng-container matColumnDef="no_of_creditors">
          <th mat-header-cell *matHeaderCellDef>{{ 'DASHBOARD.number-of-creditors' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            {{creditorCount(element)}}
          </td>
        </ng-container>
        <!-- Reason -->
        <ng-container matColumnDef="reason">
          <th mat-header-cell *matHeaderCellDef>{{ 'PAYMENTS.reason' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <div>
              <span class="badge badge-primary">{{ 'SHARED.action-required.settlement' | translate }}</span>
            </div>
          </td>
        </ng-container>
        <!-- Days in status -->
        <ng-container matColumnDef="days_in_status">
          <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.days_in_status" | translate }}</th>
          <td mat-cell *matCellDef="let element; let i = index">
            <span>{{ getDaysInStatus(element) }}</span>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
      </table>


      <!-- No Data -->
      <div *ngIf="isLoadingCases > 1 && dataBalanceDebt && dataBalanceDebt.data.length < 1" style="padding: 50px">
        <h3 class="text-center">{{ "CASES.no-cases" | translate }}</h3>
      </div>
      <!-- Pagination -->
      <mat-paginator [length]="dataBalanceDebtConfig.length" [pageSize]="dataBalanceDebtConfig.pageSize" showFirstLastButtons
                    [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="paginatorChange($event)">
      </mat-paginator>
    </div>
    <div>
      <div *ngIf="isLoadingCases" class="text-center" style="padding: 50px">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <h3>{{ 'SHARED.action-required.dm_cases' | translate }}</h3>
      <table mat-table [dataSource]="dataSource">
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <!-- Ref Number -->
        <ng-container matColumnDef="ref_number">
          <th mat-header-cell *matHeaderCellDef>{{ "CASES.model.ref_number" | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <a class="pr-3" (click)="$event.stopPropagation()" [routerLink]="['/cases', element.id, 'general']">
              {{ element.ref_number }}
            </a>
          </td>
        </ng-container>
        <!-- Last Name -->
        <ng-container matColumnDef="negotiator">
          <th mat-header-cell *matHeaderCellDef>{{ "SHARED.model.name" | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element.department_with_user[0]?.name ?? 'N/A' }}</td>
        </ng-container>
        <!-- Case Status -->
        <ng-container matColumnDef="case_status">
          <th mat-header-cell *matHeaderCellDef>{{ "CASES.case-status" | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <div>
              <span class="badge badge-primary">{{ element.status?.name }}</span>
            </div>
            <div>
              <span class="badge badge-primary-inverted">{{ element.payment_status?.name }}</span>
            </div>
          </td>
        </ng-container>
        <!-- Amount of creditors -->
        <ng-container matColumnDef="no_of_creditors">
          <th mat-header-cell *matHeaderCellDef>{{ 'DASHBOARD.number-of-creditors' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            {{creditorCount(element)}}
          </td>
        </ng-container>
        <!-- Reason -->
        <ng-container matColumnDef="reason">
          <th mat-header-cell *matHeaderCellDef>{{ 'PAYMENTS.reason' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <div>
              <span class="badge badge-primary" *ngIf="element.dm_accepted_percent > 70">{{ element.dm_accepted_percent }}% {{ 'SHARED.action-required.dm' | translate }}</span>
            </div>
            <div>
              <span class="badge badge-primary" *ngIf="element.debt_reduction > 50 && element.status_id !== 500">{{element.debt_reduction}}% {{ 'SHARED.action-required.novo' | translate }}</span>
            </div>
            <div>
              <span class="badge badge-primary" *ngIf="element.status_id == 506">Move case to DM Revisar</span>
            </div>
          </td>
        </ng-container>
        <!-- Days in status -->
        <ng-container matColumnDef="days_in_status">
          <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.days_in_status" | translate }}</th>
          <td mat-cell *matCellDef="let element; let i = index">
            <span>{{ getDaysInStatus(element) }}</span>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
      </table>


      <!-- No Data -->
      <div *ngIf="isLoadingCases < 1 && dataSource && dataSource.data.length < 1" style="padding: 50px">
        <h3 class="text-center">{{ "CASES.no-cases" | translate }}</h3>
      </div>
      <!-- Pagination -->
      <mat-paginator [length]="paginatorConfig.length" [pageSize]="paginatorConfig.pageSize" showFirstLastButtons
                    [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="paginatorChange($event)">
      </mat-paginator>
    </div>
</div>