import { Component, Inject, OnInit } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { LaravelResourceResponse } from '../../../../../../../../_base-shared/contracts/laravel-response.interface';
import { CaseService } from '../../../case.service';
import { Case, CasePrefillData } from '../../../../../../../../_base-shared/models/Case/Case';
import { FinancialOverview } from 'projects/_base-shared/models/Payment/FinancialOverview';
import { FinancialOverviewService } from '../../../../payment/financial-overview.service';
import { User } from 'projects/_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../../../_shared/services/main-global-event.service';

@Component({
    templateUrl: './target-monthly.component.html',
})

export class TargetMonthlyPaymentComponent implements OnInit {
    public form: UntypedFormGroup;
    public case: Case;
    public isSubmitting: boolean;
    public serverResponse: LaravelResourceResponse;
    private prefillData: CasePrefillData = new CasePrefillData();
    public selectedAmount: number;
    public financialOverview: FinancialOverview;
    public minimumAmount: number = 150;
    public customAmount: number  = 150;
    public disposableIncome: number;
    public isLoading: number     = 0;
    public authUser: User;
    public authorizedIds: Array<number> = [27, 36, 2403, 2497, 751562, 751600, 752733, 761789, 1, 751601, 773843, 751562, 747150, 761789, 768371, 752733, 752900];

    private subscriptions: Array<Subscription> = [];

    constructor(
        public dialogRef: MatDialogRef<TargetMonthlyPaymentComponent>,
        private fb: UntypedFormBuilder,
        private translate: TranslateService,
        private toastr: ToastrService,
        private caseService: CaseService,
        private financialOverviewService: FinancialOverviewService,
        private globalEventsService: MainGlobalEventService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit() {
        this.case = this.data.case;
        this.selectedAmount = this.data.selected ?? null;
        this.getMonthlyTargetPayment();
        this.buildForm();

        this.minimumAmount = (this.case.product_id === 19) ? 150 : this.minimumAmount;

        this.subscriptions.push(this.globalEventsService.authUser$.subscribe(user => {
          this.authUser = user;
          if(this.authUser) {
            if(this.authorizedIds.includes(this.authUser.id)) {
              this.minimumAmount = 1;
            }
          }
        }));
      // watch value changes target_monthly_payment
      this.form.get('case').get('target_monthly_payment').valueChanges.subscribe(value => {
        this.customAmount = value;
      });
    }
    private buildForm() {
        this.form = this.fb.group({
          case:    this.fb.group({
            lead:                   this.fb.group({
              affiliate_id: [
                this.case.lead?.affiliate_id ? this.case?.lead?.affiliate_id : this.prefillData?.affiliate_id,
              ],
            }),
            target_monthly_payment: [null],
            product_id:             [this.case.product_id],
            linked_cases_ids:       [this.case.linked_cases_ids || []],
            joint_application:      [this.case.joint_application || false, Validators.required],
            department_assignments: this.fb.group({
              legal_advisor_id: [
                {value: this.prefillData?.legal_advisor_id, disabled: !this.prefillData?.legal_advisor_id},
              ],
              verifier_id:      [
                {value: this.prefillData?.verifier_id, disabled: !this.prefillData?.verifier_id},
              ],
            }),
          }),
          client:  this.fb.group({...this.case.client}),
          partner: this.fb.group({...this.case.partner}),
        });
    }

    public submitForm(form, amount): void {
        if (form.invalid) {
            form.markAllAsTouched();
            return;
        }
        this.isSubmitting = true;
        const formData    = form.value;
        formData.case.target_monthly_payment = amount;

        this.caseService.update(this.case.id, formData).pipe(finalize(() => this.isSubmitting = false)).subscribe(
            res => {
              this.form.markAsPristine();
              this.case             = res.data;
              const responseMessage = this.translate.instant('CASES.editor.general.result.update.success');
              this.toastr.success(responseMessage, this.translate.instant('SHARED.success'));
            },
            error => {
              this.serverResponse   = error.error;
              const responseMessage = this.translate.instant('CASES.editor.general.result.update.error');
              this.toastr.error(error.error?.message, responseMessage);
            });
        this.dialogRef.close(this.form.value);
    }

    onCloseClick(): void {
        this.dialogRef.close();
    }

    public getMonthlyTargetPayment(): number {
        this.financialOverviewService.financialOverview$.pipe(finalize(() => this.isLoading--)).subscribe(result => {
            if (result) {
                this.financialOverview = result;
            }
        });
        let disposableIncome = this.financialOverview.income - this.financialOverview.expenses;
        this.disposableIncome = disposableIncome;
        if(this.form) {
          this.form.get('case').get('target_monthly_payment').setValidators([Validators.min(this.minimumAmount), Validators.max(disposableIncome)]);
          if (disposableIncome < this.minimumAmount) {
              this.form.get('case').get('target_monthly_payment').setValidators([Validators.min(this.minimumAmount), Validators.max(this.minimumAmount)]);
              this.form.get('case').get('target_monthly_payment').updateValueAndValidity();
              return this.minimumAmount;
          }
        }
        if (disposableIncome * 0.5 > this.minimumAmount) {
            return disposableIncome * 0.5;
        } else {
            return this.minimumAmount;
        }
    }
}
