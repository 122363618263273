import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckIfComponentIsDirty } from '../../../../../_base-shared/guards/check-if-comopnent-dirty.guard';
import { MainAuthGuard } from '../../_shared/guards/main-auth-guard.service';
import { MainPermissionGuard } from '../../_shared/guards/main-permission-guard.service';
import { PackagerCasesPermissionGuard } from '../../_shared/guards/packager-cases-permission.guard';
import { DocumentComponent } from '../document/document.component';
import { NotificationListComponent } from '../notification/notification-list/notification-list.component';
import { TaskListComponent } from '../task/task-list/task-list.component';
import { CaseDashboardComponent } from './case-dashboard/case-dashboard.component';
import { CaseCreditorListComponent } from './case-detail/case-creditor-list/case-creditor-list.component';
import { CaseGeneralDetailComponent } from './case-detail/case-detail-general/case-general-detail.component';
import { CaseDetailComponent } from './case-detail/case-detail.component';
import { CaseDocumentListNewComponent } from './case-detail/case-document-list-new/case-document-list-new.component';
import { CaseClientActivityComponent } from './case-detail/case-client-activity/case-client-activity.component';
import { CaseDocumentListV2Component } from './case-detail/case-document-list-v2/case-document-list-v2.component';
import { CaseDocumentListComponent } from './case-detail/case-document-list/case-document-list.component';
import { CaseAepCreatorComponent } from './case-detail/case-draft/case-aep-creator/case-aep-creator.component';
import { CaseDraftComponent } from './case-detail/case-draft/case-draft.component';
import { DemandGeneratorComponent } from './case-detail/case-draft/demand-generator/demand-generator.component';
import { EpiRequestGeneratorComponent } from './case-detail/case-draft/epi-request/epi-request-generator.component';
import { LsoGeneratorComponent } from './case-detail/case-draft/lso-generator/lso-generator.component';
import { CasePaymentComponent } from './case-detail/case-payment/case-payment.component';
import {
  ClientInstallmentListPreviewComponent
} from './case-detail/case-payment/client-installment-list-preview/client-installment-list-preview.component';
import { CaseAssetEditorComponent } from './case-editor/case-asset-editor/case-asset-editor.component';
import { CaseCreditorEditorComponent } from './case-editor/case-creditor-editor/case-creditor-editor.component';
import { CaseEditorComponent } from './case-editor/case-editor.component';
import { CaseExpenseEditorComponent } from './case-editor/case-expense-editor/case-expense-editor.component';
import { CaseGeneralEditorComponent } from './case-editor/case-general-editor/case-general-editor.component';
import { CaseIncomeEditorComponent } from './case-editor/case-income-editor/case-income-editor.component';
import { CaseOutcomeComponent } from './case-editor/case-outcome/case-outcome.component';
import { CasePaymentEditorComponent } from './case-editor/case-payment-editor/case-payment-editor.component';
import { CaseListComponent } from './case-list/case-list.component';
import { CaseSnapshotComponent } from './case-snapshot/case-snapshot.component';
import { CreditorProposalPreviewComponent } from './creditor-proposal-preview/creditor-proposal-preview.component';
import {CaseLoanCalculatorComponent} from './case-detail/case-loan-calculator/case-loan-calculator.component';
import { CreditorCaseListComponent } from './creditor-cases/creditor-list.component';
import { CreditorCaseSettlementComponent } from './case-creditor-settlement/settlement.component';
import { CreditorCaseUpdateBalanceComponent } from './case-creditor-updatebalance/updatebalance.component';

const caseListChildren: Routes = [
  {
    path:        'create',
    component:   CaseEditorComponent,
    canActivate: [MainAuthGuard, MainPermissionGuard],
    data:        {editorType: 'create', allowAgent: false, allowManager: true},
    children:    [
      {
        path:      'general',
        component: CaseGeneralEditorComponent,
        data:      {editorType: 'create'},
      },
    ],
  },
  {
    path:        ':id',
    component:   CaseDetailComponent,
    canActivate: [MainAuthGuard, MainPermissionGuard],
    children:    [
      {path: '', redirectTo: 'general', pathMatch: 'full'},
      {path: 'general', component: CaseGeneralDetailComponent},
      {path: 'documents', component: CaseDocumentListComponent},
      {path: 'creditors', component: CaseCreditorListComponent},
      {path: 'legal', component: CaseDraftComponent},
      {path: 'legal-router/document-generator/demand', component: DemandGeneratorComponent},
      {path: 'legal-router/document-generator/epi', component: EpiRequestGeneratorComponent},
      {path: 'legal-router/document-generator/lso', component: LsoGeneratorComponent},
      {path: 'loan-calculator', component: CaseLoanCalculatorComponent},
      {path: 'payments', component: CasePaymentComponent},
      {path: 'documents-new', component: CaseDocumentListNewComponent},
      {path: 'client-activity', component: CaseClientActivityComponent},
      {path: 'documents-v2', component: CaseDocumentListV2Component},
      {path: 'aep/create', component: CaseAepCreatorComponent},
      {path: 'snapshot', component: CaseSnapshotComponent},
      {path: 'payments/:caseUuid/preview-client-installment-list', component: ClientInstallmentListPreviewComponent},
    ],
    data:        {allowAgent: false, allowManager: true},
  },
  {
    path:        ':id/edit',
    component:   CaseEditorComponent,
    canActivate: [MainAuthGuard, MainPermissionGuard],
    data:        {editorType: 'edit', allowAgent: true, allowManager: true},
    children:    [
      {
        path:          'general',
        component:     CaseGeneralEditorComponent,
        canDeactivate: [CheckIfComponentIsDirty],
        data:          {editorType: 'edit'},
      },
      {path: 'creditors', component: CaseCreditorEditorComponent, canDeactivate: [CheckIfComponentIsDirty]},
      {path: 'assets', component: CaseAssetEditorComponent, canDeactivate: [CheckIfComponentIsDirty]},
      {path: 'incomes', component: CaseIncomeEditorComponent, canDeactivate: [CheckIfComponentIsDirty]},
      {path: 'expenses', component: CaseExpenseEditorComponent, canDeactivate: [CheckIfComponentIsDirty]},
      {path: 'outcome', component: CaseOutcomeComponent},
      {path: 'payment', component: CasePaymentEditorComponent, canDeactivate: [CheckIfComponentIsDirty]},
    ],
  },
];

const routes: Routes = [
  {
    path:        'cases',
    children:    [
      {path: '', component: CaseListComponent, data: {onlyRelated: false}},
      {path: 'settlement', component: CreditorCaseSettlementComponent},
      {path: 'updatebalance', component: CreditorCaseUpdateBalanceComponent},
      ...caseListChildren,
    ],
    canActivate: [MainAuthGuard, MainPermissionGuard],
    data:        {filterType: 'case', allowAgent: true, allowManager: true},
  },
  {
    path:     'affiliate-cases',
    children: [
      {path: '', component: CaseListComponent, data: {filterType: 'affiliate_case'}},
    ],
  },
  {
    path:     'packager-cases',
    children: [
      {path:         '',
        component:   CaseListComponent,
        data:        {filterType: 'packager_non_transferred'},
        canActivate: [PackagerCasesPermissionGuard],
      },
    ],
  },
  {
    path:      'case-dashboard',
    component: CaseDashboardComponent,
    children:  [
      {path: '', redirectTo: 'cases', pathMatch: 'full'},
      {
        path:        'cases',
        canActivate: [MainAuthGuard, MainPermissionGuard],
        data:        {filterType: 'case', allowAgent: true, allowManager: false},
        children:    [
          {path: '', component: CaseListComponent},
          ...caseListChildren,
        ],
      },
      {
        path:     'my-cases',
        children: [
          {path: '', component: CaseListComponent, data: {filterType: 'case', onlyRelated: true}},
          ...caseListChildren,
        ],
      },
      {
        path:     'tasks',
        children: [
          {path: '', component: TaskListComponent},
        ],
      },
      {
        path:     'my-tasks',
        children: [
          {path: '', component: TaskListComponent, data: {onlyRelated: true}},
        ],
      },
      {
        path:     'notifications',
        children: [
          {path: '', component: NotificationListComponent},
        ],
      },
    ],
  },
  {
    path:     'case-dashboard',
    component: CreditorCaseListComponent,
    children:  [
      {path: '', redirectTo: 'creditors', pathMatch: 'full'},
      {
        path:        'creditors',
        canActivate: [MainAuthGuard, MainPermissionGuard],
        data:        {filterType: 'case', allowAgent: true, allowManager: false},
        children:    [
          {path: '', component: CaseListComponent},
          ...caseListChildren,
        ],
      },
    ],
  },
  {
    path:     'new-partner-cases',
    children: [
      {path: '', component: CaseListComponent, data: {newPartner: 1}},
    ],
  },
  {path: 'app-documents/download', component: DocumentComponent},
  {path: 'creditor-proposal-preview', component: CreditorProposalPreviewComponent},
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class CaseRoutingModule {
}
