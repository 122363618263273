
    <div class="d-flex flex-row-reverse justify-content-between align-items-center pb-2 mt-3 w-100 px-3">
        <div class="mx-2">
          <!--<span>{{ 'CASES.single.payments.balance_overview.filtered_balance.progress' | translate }}:</span>-->
          <!--<span class="font-weight-bold">{{+(filteredInstallmentsBalance.totalPaid) | number: '1.2-2'}}
            € / {{+(filteredInstallmentsBalance.totalToPay) | number: '1.2-2'}} € </span>-->
            <span>Total payments received:</span>
            <span class="font-weight-bold">€{{+(filteredInstallmentsBalance.totalPaid) | number: '1.2-2'}}</span>
        </div>
        <div class="mx-2">
            <span>Total Debts Settled:</span>
            <span class="font-weight-bold">€{{+(case.settlement_paid) | number: '1.2-2'}}</span>
        </div>
        <div class="mx-2">
            <span>Total Plan Fees:</span>
            <span class="font-weight-bold">€{{+(case.settlement_fees) | number: '1.2-2'}}</span>
        </div>
        <div class="mx-2">
            <span>Balance Available:</span>
            <span class="font-weight-bold">€{{+(case.settlement_balance) | number: '1.2-2'}}</span>
        </div>
    </div>
  
  <div class="mb-4" style="box-shadow: none">
    <div class="card-header" style="border: 1px solid #e4e4e4; position: relative">
      <h2 class="text-center m-0">Client Activity</h2>
    </div>
    <div class="card-body p-0">
  
      <div class="mat-elevation-z8" style="border: 1px solid rgba(0, 0, 0, 0.12); border-top: none">
        <table style="width: 100%" mat-table matSort (matSortChange)="sortData($event)"
               [dataSource]="showOnlyUnpaid ? dataSourceUnpaid : dataSource" multiTemplateDataRows>
               
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                (change)="$event ? masterToggle(selectionPayments, showOnlyUnpaid ? dataSourceUnpaid : dataSource) : null"
                color="primary"
                [checked]="selectionPayments.hasValue() && isAllSelected(selectionPayments, showOnlyUnpaid ? dataSourceUnpaid : dataSource)"
                [indeterminate]="selectionPayments.hasValue() && !isAllSelected(selectionPayments, showOnlyUnpaid ? dataSourceUnpaid : dataSource)">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                            (change)="$event ? selectionPayments.toggle(row) : null"
                            [checked]="selectionPayments.isSelected(row)">
              </mat-checkbox>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="client-name">
            <th mat-header-cell *matHeaderCellDef>{{ "PAYMENTS.name" | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{element.case.client.first_name + ' ' +
            element.case.client.last_name}} </td>
          </ng-container>
  
          <!-- <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>{{ "PAYMENTS.email" | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{element.case.client.email}} </td>
          </ng-container> -->
  
          <ng-container matColumnDef="next-payment">
            <th mat-header-cell *matHeaderCellDef>{{ "PAYMENTS.payment-schedule" | translate }}</th>
            <td mat-cell *matCellDef="let element">
              <div *ngIf="element.active">
                <span>{{element.term_date | toDateObject | date }}</span>
                <p class="m-0" style="color: red"
                   *ngIf="element.amount_paid < element.amount && (element.term_date | toDateObject) < now">
                  <small>{{"PAYMENTS.missed-payment" | translate }}</small>
                </p>
              </div>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="date-paid">
            <th mat-header-cell *matHeaderCellDef>{{ "PAYMENTS.date-paid" | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{element.date_paid | toDateObject | date }}
              <ng-container *ngIf="element.date_paid">
                <p class="m-0" style="color: green" *ngIf="element.amount_paid === element.amount">
                  <small>{{"PAYMENTS.paid" | translate }}</small>
                </p>
                <p class="m-0" style="color: orange"
                   *ngIf="element.amount_paid < element.amount && element.amount_paid > 0">
                  <small>{{"PAYMENTS.partial-paid" | translate }}</small>
                </p>
              </ng-container>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef>{{ "PAYMENTS.amount" | translate }}</th>
            <td mat-cell *matCellDef="let element">
              <small class="font-weight-lighter">{{element.amount_paid | number: '1.2-2'}}€
                / {{element.amount | number: '1.2-2'}}€ </small>
              <span class="font-weight-bold">- {{element.amount - element.amount_paid | number: '1.2-2'}}€</span>
              <div class="progress" style="max-width: 120px">
                <div class="progress-bar progress-bar-striped" role="progressbar"
                     [style]="{width: getPercent(element.amount_paid, element.amount)  + '%'}"
                     [attr.aria-valuenow]="getPercent(element.amount_paid, element.amount)"
                     [attr.aria-valuemin]="0" [attr.aria-valuemax]="100">{{ getPercent(element.amount_paid,
                  element.amount) }}%
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="cred-settlement">
            <th mat-header-cell *matHeaderCellDef>Creditor Settlement</th>
            <td mat-cell *matCellDef="let element"> 
                {{ element.cred_settlement ? ('€' + (element.cred_settlement | number:'1.2-2')) : '' }}
            </td>
            <!--<th mat-header-cell *matHeaderCellDef>{{ "PAYMENTS.name" | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{element.case.client.first_name + ' ' +
            element.case.client.last_name}} </td>-->
          </ng-container>

          <ng-container matColumnDef="monthly-fees">
            <th mat-header-cell *matHeaderCellDef>Monthly Fees</th>
            <td mat-cell *matCellDef="let element"> 
                {{ element.monthly_fees ? ('€' + (element.monthly_fees | number:'1.2-2')) : '' }}
            </td>
            <!--<th mat-header-cell *matHeaderCellDef>{{ "PAYMENTS.name" | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{element.case.client.first_name + ' ' +
            element.case.client.last_name}} </td>-->
          </ng-container>

          <ng-container matColumnDef="negotiation-fees">
            <th mat-header-cell *matHeaderCellDef>Negotiation Fees</th>
            <td mat-cell *matCellDef="let element"> 
                {{ element.negotiation_fees ? ('€' + (element.negotiation_fees | number:'1.2-2')) : '' }}  
            </td>
            <!--<th mat-header-cell *matHeaderCellDef>{{ "PAYMENTS.name" | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{element.case.client.first_name + ' ' +
            element.case.client.last_name}} </td>-->
          </ng-container>

          <ng-container matColumnDef="balance-of-funds">
            <th mat-header-cell *matHeaderCellDef>Balance Of Funds</th>
            <td mat-cell *matCellDef="let element"> 
                {{ element.balance_of_funds ? ('€' + (element.balance_of_funds | number:'1.2-2')) : '' }}  
            </td>
            <!--<th mat-header-cell *matHeaderCellDef>{{ "PAYMENTS.name" | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{element.case.client.first_name + ' ' +
            element.case.client.last_name}} </td>-->
          </ng-container>

          <ng-container matColumnDef="notes">
            <th mat-header-cell *matHeaderCellDef>Notes</th>
            <td mat-cell *matCellDef="let element"> {{ element.notes }}  </td>
            <!--<th mat-header-cell *matHeaderCellDef>{{ "PAYMENTS.name" | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{element.case.client.first_name + ' ' +
            element.case.client.last_name}} </td>-->
          </ng-container>
  
          <!--<ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>{{ "SHARED.actions" | translate }}</th>
            <td mat-cell *matCellDef="let element">
              <button *ngIf="element.active" mat-stroked-button [matMenuTriggerFor]="menu"
                    [disabled]="!isElementRestricted()">
                {{ "SHARED.actions" | translate }}
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item class="dropdown-item" (click)="saveTermId(element)">
                  {{ 'PAYMENTS.change-date' | translate }}
                </button>
                <button *ngIf="element.case_payment_plan.type.default" mat-menu-item class="dropdown-item"
                        (click)="openChangeAmountModal(element)">
                  {{ 'PAYMENTS.change-value' | translate }}
                </button>
                <a *ngIf="authUser.role_id === 5 &&
                 (element.case_payment_plan.type.default && element.amount_paid === 0)"
                   mat-menu-item class="dropdown-item clickable" (click)="deleteInstallment(element.id)">
                  {{ 'CASES.single.payments.installment_list.table.actions.delete' | translate }}
                </a>
                <a *ngIf="authUser.role_id === 5 &&
                 (element.case_payment_plan.type.default && element.amount > 0 && (element.amount !== element.amount_paid))"
                   mat-menu-item class="dropdown-item clickable" (click)="markAsPromo(element)">
                  {{ 'CASES.single.payments.installment_list.table.actions.mark_as_promo' | translate }}
                </a>
              </mat-menu>
            </td>
          </ng-container>-->
    
        <!-- Header row first group -->
        <ng-container matColumnDef="header-row-first-group">
          <th mat-header-cell *matHeaderCellDef
              colspan="8" style="text-align: center;">
              {{ "DISTRIBUTION.installments.dist_pay_plan" | translate }}
          </th>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
         <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row" [ngClass]="'custom-row'"
             [class.example-expanded-row]="expandedElement === element">
         </tr>
          
        </table>
  
        <div *ngIf="isLoading" class="text-center" style="padding: 50px">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
  
        <div *ngIf="!isLoading && dataSource && dataSource.data.length < 1" style="padding: 50px">
          <h3 class="text-center">{{ "PAYMENTS.no-payments" | translate }}</h3>
        </div>
  
        <mat-paginator
          [length]="paginatorConfig.length"
          [pageSize]="paginatorConfig.pageSize"
          [pageSizeOptions]="[5, 10, 20, 25]"
          showFirstLastButtons
        ></mat-paginator>
      </div>
    </div>
  </div>
