import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { CaseService } from '../../../admin/case/case.service';
import { CreditorService } from '../../../admin/creditor/creditor.service';
import { Product } from 'projects/_base-shared/models/Product';
import { ProductService } from '../../../admin/case/product.service';
import { CaseCreditorStatusService } from '../../../admin/status/case-creditor/status-list/case-creditor-status.service';
import { CaseCreditorStatus } from 'projects/_base-shared/models/Case/CaseCreditorStatusLog';
import { User } from '../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';

@Component({
  selector:    'app-change-creditor-status',
  templateUrl: './change-creditor-status.component.html',
  styleUrls:   ['./change-creditor-status.component.scss'],
})
export class ChangeCreditorStatusComponent implements OnInit {
  public type: 'public_debts' | 'secured_creditors' | 'unsecured_creditors' | 'claim_creditors';
  public form: UntypedFormGroup;
  public formActive = false;
  public isLoading  = 0;
  public products: Array<Product>       = [];
  public caseCreditorStatuses: Array<CaseCreditorStatus> = [];
  public authUser: User;

  constructor(public dialogRef: MatDialogRef<ChangeCreditorStatusComponent>,
              private fb: UntypedFormBuilder,
              private toast: ToastrService,
              private translate: TranslateService,
              private caseService: CaseService,
              private creditorService: CreditorService,
              public translateService: TranslateService,
              private productService: ProductService,
              private caseCreditorStatusService: CaseCreditorStatusService,
              private globalEventService: MainGlobalEventService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.globalEventService.authUser$.subscribe(user => this.authUser = user);
    this.type = this.data.type;
    console.log(this.type);
    this.buildForm();
    this.fetchProducts();
    this.fetchCaseCreditorStatuses();

    this.form.get('status').valueChanges.subscribe(value => {
      if (value.includes('dm')) {
        const product = this.products.find(p => p.name.toLowerCase() === 'dmp');
        this.form.get('product_id').setValue(product.id);
      }
      if (value.includes('fds')) {
        const product = this.products.find(p => p.name.toLowerCase() === 'fds');
        this.form.get('product_id').setValue(product.id);
      }
      if (value.includes('qw') || value.includes('refinanced') || value.includes('exclude') || value.includes('declined')) {
        const product = this.products.find(p => p.name.toLowerCase() === 'exclude');
        this.form.get('product_id').setValue(product.id);
      }
      if (value.includes('ds')) {
        const product = this.products.find(p => p.name.toLowerCase() === 'debt settlement');
        this.form.get('product_id').setValue(product.id);
      }
    });
  }

  buildForm() {
    this.form = this.fb.group({
      status: ['mandate_sent', [Validators.required]],
      product_id: [null, [Validators.required]],
    });

    this.formActive = true;
  }

  closeDialog(value = null) {
    this.dialogRef.close(value);
  }

  submit() {
    if (this.form.valid) {
      this.isLoading++;
      const data = {};
      if (!this.data.change_all) { // Change all is used when changing status in drafting tab
        // @ts-ignore
        data.response_received = this.form.value.status;
        // @ts-ignore
        data.product_id        = this.form.value.product_id;
        // @ts-ignore
        data.selected_ids      = this.data.ids;
      }

      if (this.type === 'public_debts') {
        this.creditorService.updateCasePublicDebt(this.data.case.id, this.data.ids[0], data)
          .pipe(finalize(() => this.isLoading--))
          .subscribe(
            value => {
              this.toast.success(this.translate.instant('CASES.details.change-status-success'));
              this.closeDialog(this.form.value.status);
            },
            error => {
              if (error.error?.data?.message) {
                this.toast.error(error.error.data.message);
              } else {
                this.toast.error(this.translate.instant('CASES.details.change-status-error'));
              }
            });
      } else {
        if (this.data.change_all) {
          // @ts-ignore
          data.change_all_creditors = 1;
          // @ts-ignore
          data.product_id           = this.form.value.product_id;
          // @ts-ignore
          data.drafting_status      = this.form.value.status;
        }
        console.log(data);
        this.creditorService.updateCaseCreditor(this.data.ids[0], data)
          .pipe(finalize(() => this.isLoading--))
          .subscribe(
            value => {
              this.toast.success(this.translate.instant('CASES.details.change-status-success'));
              this.closeDialog(this.form.value.status);
            },
            error => {
              if (error.error?.data?.message) {
                this.toast.error(error.error.data.message);
              } else {
                this.toast.error(this.translate.instant('CASES.details.change-status-error'));
              }
            });
      }
    } else {
      this.form.markAllAsTouched();
    }
  }

  private fetchProducts() {
    this.isLoading++;
    this.productService.index({ select_all: 1 }).pipe(finalize(() => this.isLoading--)).subscribe(
      result => this.products = result.data
    );
  }

  private fetchCaseCreditorStatuses(): void {
    this.isLoading++;
    const data = {
      select_all: 1,
    };
    this.caseCreditorStatusService.index(data).pipe(finalize(() => this.isLoading--))
      .subscribe(res => {
        res.data = res.data.filter(status => status.is_system_status !== 1);
        if(this.authUser.locale === 'en') {
          this.caseCreditorStatuses = res.data.sort((a,b) => a.name_en.localeCompare(b.name_en));
        } else {
          this.caseCreditorStatuses = res.data.sort((a,b) => a.name_es.localeCompare(b.name_es));
        }
      });
  }
}
