import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Case } from '../../../../../../_base-shared/models/Case/Case';
import { CaseListFilter } from '../../../../../../_base-shared/models/Case/CaseListFilter';
import { User } from '../../../../../../_base-shared/models/User/User';
import { MainBaseApiService } from '../../../_shared/services/main-base-api.service';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { CaseService } from '../case.service';
// import { CaseListFiltersComponent } from './case-list-filters/case-list-filters.component';
import { environment } from '../../../../environments/environment';
import { saveAs } from 'file-saver';
import { CaseCreditorService } from '../case-creditor.service';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-creditor-case-settlement',
  templateUrl: './settlement.component.html',
  styleUrls: ['./settlement.component.scss']
})

export class CreditorCaseSettlementComponent implements OnInit, AfterViewInit, OnDestroy {

  math = Math;
  // Wrapper
  @ViewChild('tabs') tabGroup: MatTabGroup;
  public bulkActionForm: UntypedFormGroup;
  public onlyRelated: boolean;
  public filterType: 'case' | 'legal' | 'affiliate_case' | 'customer_contact' | 'packager_non_transferred' = null;
  // End of wrapper

  public authUser: User;
  public isLoadingExport = 0;
  public submittingDistribution: boolean;

  public filtersReady = false;
  public caseListFilter: CaseListFilter;

  public percentageReduction: number;

  // Datatable
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public isLoadingCases                      = 0;
  public initialSelection                    = [];
  public allowMultiSelect                    = true;
  public selection                           = new SelectionModel(this.allowMultiSelect, this.initialSelection);
  public showInvoiceStatus: boolean;
  public pageSelected                        = false;
  public totalResults: number;
  public totalPages: number;
  public dataSource: MatTableDataSource<Case>;
  public defaultPaginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public paginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public defaultSort: { direction: 'asc' | 'desc', active: 'created_at' };
  public creditorDataSource: Array<string> = [];
  public negotiatorCreditorCols: Array<string> = [
    'creditor', 'creditor_status', 'debt_amount', 'negotiated_amount', 'percent_reduction'
  ];
  public userIsCreditor: boolean = false;
  public creditorAcceptPlan = new SelectionModel(this.allowMultiSelect, this.initialSelection);
  public creditorRejectPlan = new SelectionModel(this.allowMultiSelect, this.initialSelection);
  public storageUrl = environment.STORAGE_URL + '/';

  public isManager: boolean = false;

  // End Datatable
  private initialFetchComplete: boolean;
  private subscriptions: Array<Subscription> = [];
  public isUserCreditorNegotiator            = false;

  public displayedColumns: string[] = [
    'id_card',
    'last_name',
    // 'debt_amount',
    'no_of_creditors',
    'debt_with_cred',
    // 'proposed_settlement',
    'negotiated_amount',
    'final_settlement',
    'actions',
  ];

  constructor(
    public globalEventsService: MainGlobalEventService,
    private caseService: CaseService,
    private caseCreditorService: CaseCreditorService,
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.push(this.globalEventsService.authUser$.subscribe(user => this.authUser = user));
    this.defaultPaginatorConfig = { pageIndex: 0, pageSize: 20, length: 1 };
    this.defaultSort            = { direction: 'desc', active: 'created_at' };
    this.paginatorConfig        = this.defaultPaginatorConfig;
    this.fetchCreditorCases();
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }

  public fetchCreditorCases(statusId = null) {
    this.fetchCases(statusId);
  }

  private fetchCases(statusId = null): void {
    this.clearSelection();  //  Reset global select

    const relations = ['packager_status', 'case_invoice_status', 'call_status', 'packager', 'affiliate', 'product', 'lead.affiliate',
      'payment_status', 'status', 'latest_case_status_log', 'oldest_case_pending_document', 'creditors', 'doc_status', 'case_status_logs', 'expense', 'distribution'];
    if (this.filterType === 'customer_contact') {
      relations.push('notification_histories.notifiable');
      relations.push('notification_histories.staff');
    }

      let whereNotStatuses = [335,466,113]
      let whereStatuses = [null];
      let case_creditor_statuses = [statusId];
      let cc_not_statuses = [14,42,47,11];
    // push statuses and case_creditor_statuses here
    this.caseListFilter = {
      ...this.caseListFilter,
      page: this.paginatorConfig.pageIndex + 1,
      per_page: this.paginatorConfig.pageSize,
      not_statuses: whereNotStatuses,
      cc_not_statuses: cc_not_statuses,
      case_creditor_statuses: case_creditor_statuses
    };

    if(statusId === null){
      this.caseListFilter.not_statuses.push(364);
      this.caseListFilter.cc_not_statuses.push(25);
    }
    if(statusId === 50){
        this.caseListFilter.activity_status = 'all';
        this.caseListFilter.not_statuses = this.caseListFilter.not_statuses.filter(item => item !== 393);
        this.caseListFilter.not_statuses = this.caseListFilter.not_statuses.filter(item => item !== 500);
    } else {
        delete this.caseListFilter.activity_status;
        this.caseListFilter.not_statuses.push(393);
        this.caseListFilter.not_statuses.push(500);
    }

    const requestData = MainBaseApiService.convertFiltersForRequest(
      { ...this.caseListFilter, with: relations }, 'get'
    );

    this.dataSource = new MatTableDataSource<Case>([]);
    this.isLoadingCases++;
    this.subscriptions.push(
      this.caseService.index(requestData).pipe(finalize(() => this.isLoadingCases--)).subscribe(
        result => {
          this.clearSelection();
          this.dataSource             = new MatTableDataSource<Case>(result.data);
          this.dataSource.sort        = this.sort;
          this.paginatorConfig.length = result.meta.total;
          this.totalResults           = result.meta.total;
          this.totalPages             = result.meta.last_page;
        },
        err => console.error(err)
      )
    );
  }

  private clearSelection() {
    this.pageSelected = false;  //  Hide global select
    // delete this.caseListFilter.cases;
    this.creditorDataSource = [];
  }

  public debtWithSelectedCreditors(el) {
    let debt = 0;
    el.forEach(creditor => {
      if (creditor.name === this.authUser.id_card) {
        debt += creditor.pivot.initial_balance;
      }
    });
    return debt;
  }

  public downloadMandateFile(element): void {
    saveAs(this.storageUrl + element.case.contracts[0].mandate_location);
  }

  public checkForDNIFiles(element): boolean {
    let dniFiles = [];
    element.case.files.forEach(element => {
      if (element.document_type_id === 1) {
        dniFiles.push(element);
      }
    });
    return dniFiles.length > 0;
  }

  public openDniDialog(element): void {
    let dniFiles = [];
    element.case.files.forEach(element => {
      if (element.document_type_id === 1) {
        dniFiles.push(element);
      }
    });
    let html = `
      <div class="container">
        <div class="row">
          <div class="col-12">
          ${dniFiles.map((file, index) => `
            <div style="padding-bottom: 25px;">
              <img src="${this.storageUrl + file.location}" style="width: 100%; height: auto;">
            </div>
          `).join('')}
        </div>  
    `;
    Swal.fire({
      width: '80%',
      heightAuto: true,
      title: 'DNI',
      html: html,
      showCloseButton: true,
      showConfirmButton: false,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'Cerrar',
      confirmButtonColor: '#886ab5',
      showClass: {backdrop: 'swal2-noanimation', popup: '', icon: ''},
      hideClass: {popup: ''},
    });
  }

  public yourFn($event){
    if($event.index === 0){
      this.displayedColumns = [
        'id_card',
        'last_name',
        'debt_amount',
        'no_of_creditors',
        'debt_with_cred',
        'proposed_settlement',
        'negotiated_amount',
        'final_settlement',
        'actions',
      ];
      this.fetchCreditorCases();
    }else if($event.index === 1){
        this.displayedColumns = [
          'id_card',
          'last_name',
          'debt_with_cred',
          'negotiated_amount',
          'status'
        ];
      this.fetchCreditorCases(50);
    }
  }

  public creditorCount(clientCase) {
    let i = 0;
    clientCase.forEach(element => {
      if(element.pivot?.type == 'unsecured') {
        i++;
      }
    });
    return i;
  }

  public paginatorChange($event: PageEvent): void {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;

    this.caseListFilter.page     = this.paginatorConfig.pageIndex + 1;
    this.caseListFilter.per_page = this.paginatorConfig.pageSize;
    this.fetchCases();
    this.creditorDataSource = [];
  }

  public calculateProposedSettlement(element): number {
    let total = 0;
    const debt_amount = element.initial_balance;
    const dm_amount   = element.negotiated_amount;
    const percent     = 20;
    const debt_proposed = debt_amount / 100 * percent;
    return debt_proposed > dm_amount ? dm_amount : debt_proposed;
  }

  public acceptSettlementAmount(element) {
    let input = document.getElementById(element.id) as HTMLInputElement;
    const case_id = element.case.id;
    const requestData = {
      amount: input.value
    };
    this.caseCreditorService.acceptSettlementAmount(case_id, element.id, requestData).subscribe(
      res => {
        this.fetchCreditorCases();
      },
      err => {
        console.error(err);
      }
    );
  }
}