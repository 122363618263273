<h2 style="margin: 0px; padding: 0px;" mat-dialog-title>Transfer Fees</h2>
<div class="alert alert-danger" role="alert" *ngIf="error"></div>
<mat-dialog-content>
    <div [formGroup]="form">
        <app-input type="number" formControlName="settlement_received" [fullWidth]="true" [extraLabel]="true"
            label="Received" [showClear]="false">
        </app-input>
        
        <app-input type="number" formControlName="settlement_fees" [fullWidth]="true" [extraLabel]="true"
            label="Fees" [showClear]="false">
        </app-input>
        
        <app-input type="number" formControlName="settlement_balance" [fullWidth]="true" [extraLabel]="true"
            label="Funds" [showClear]="false">
        </app-input>
    </div>
    <p class="alert alert-danger" role="alert" *ngIf="unallocated > 0 || unallocated < 0">
        Unallocated funds: {{ unallocated | currency }}
    </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="primary" [disabled]="!form.valid || (unallocated > 0 || unallocated < 0)" (click)="transfer()">
        <span *ngIf="loading">Loading...</span>
        <span *ngIf="!loading">Transfer</span>
    </button>
  <button mat-button mat-dialog-close color="primary">Close</button>
</mat-dialog-actions>