import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { CaseService } from '../../../../case.service';
import { Case } from 'projects/_base-shared/models/Case/Case';

@Component({
  selector:    'app-record-payment-modal',
  templateUrl: './transfer-fees.component.html',
  styles:      [
    `
      .record-row:hover {
        background-color: #E0EFCE;
      }
    `]
})

export class TransferFeesComponent implements OnInit {
  public loading = 0;
  public error     = '';
  public form: UntypedFormGroup;
  public formActive = false;
  public unallocated = 0;

  constructor(
    public translateService: TranslateService,
    public dialogRef: MatDialogRef<TransferFeesComponent>,
    public caseService: CaseService,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { case: Case }) {
  }

    ngOnInit(): void {
        console.log('loaded');
        console.log(this.data.case);
        this.buildForm();
    }

    public buildForm(): void {
        this.form = this.fb.group({
            settlement_received: new UntypedFormControl(this.data.case.settlement_received, [Validators.required]),
            settlement_fees: new UntypedFormControl(this.data.case.settlement_fees, [Validators.required]),
            settlement_balance: new UntypedFormControl(this.data.case.settlement_balance, [Validators.required]),
        });

        this.form.valueChanges.subscribe(() => {
            let total = this.form.get('settlement_received').value;
            let sum = Number(this.form.get('settlement_fees').value) + Number(this.form.get('settlement_balance').value);
            this.unallocated = total - sum;
        });

    }

    public transfer(): void {
        this.loading++;
        this.error = '';

        this.form.value.settlement_received = Number(this.form.value.settlement_received);
        this.form.value.settlement_fees = Number(this.form.value.settlement_fees);
        this.form.value.settlement_balance = Number(this.form.value.settlement_balance);

        const requestData = {
            case: {...this.data.case, ...this.form.value},
            partner: this.data.case.partner ?? [],
            client: this.data.case.client,
        }

        if (this.data.case.joint_application) {
            requestData.partner = this.data.case.partner;
        }
        if (!requestData.client.address?.address_1) {
          requestData.client.address.address_1 = null;
        }

        this.caseService.update(this.data.case.id, requestData).pipe(
            finalize(() => this.loading--)
        ).subscribe(
            () => {
                this.dialogRef.close();
            }
        );
    }
}
