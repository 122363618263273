import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { DebtCancellationModel } from '../../../../../_base-shared/models/Document/DebtCancellationModel';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class CaseDocumentService extends MainBaseApiService {

  public indexCaseDocuments(caseId: number, relations: Array<string> = []): Observable<LaravelResourceResponse<any>> {
    const params = MainBaseApiService.convertFiltersForRequest({with: relations}, 'get');
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/cases/' + caseId + '/case-documents', {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  public index(caseId: number, relations: Array<string> = []): Observable<LaravelResourceResponse<any>> {
    const params = MainBaseApiService.convertFiltersForRequest({with: relations}, 'get');
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/cases/' + caseId + '/documents', {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  public showPropuestoModelo(caseId: number): Observable<LaravelResourceResponse<any>> {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/cases/' + caseId + '/propuesto-modelo')
      .pipe(catchError(response => this.handleError(response)));
  }

  changeStatus(data): Observable<LaravelResourceResponse> {
    return this.http.patch<LaravelResourceResponse>(
      `${ this.apiUrl }/cases/${ data.caseId }/documents/${ data.fileId }`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public setDocAsCompleted(data, caseId): Observable<LaravelResourceResponse> {
    return this.http.patch<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/document-requests`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  // changeType(data): Observable<LaravelResponse> {
  //   return this.http.patch<LaravelResponse>(`${this.apiUrl}/cases/${data.caseId}/documents/${data.fileId}`, data);
  // }

  request(caseId, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/document-requests`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  requestNew(caseId, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/document-requests-new`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  resendRequest(data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(
      `${ this.apiUrl }/cases/${ data.caseId }/document-requests/${ data.fileId }/resend-request`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  uploadFiles(uuId, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ uuId }/documents-upload`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  uploadFilesLegal(caseId, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/legal-documents-upload`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  uploadFilesCourt(caseId, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/court-documents-upload`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  uploadFilesBank(caseId, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/legal-documents-upload`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  changeStatusLegal(caseId, fileId, data): Observable<LaravelResourceResponse> {
    return this.http.patch<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/legal-documents-upload/${ fileId }`,
      data).pipe(catchError(response => this.handleError(response)));
  }

  changeStatusCourt(caseId, fileId, data): Observable<LaravelResourceResponse> {
    return this.http.patch<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/court-documents-upload/${ fileId }`,
      data).pipe(catchError(response => this.handleError(response)));
  }

  removeUploadedFileLegal(caseId, fileId): Observable<LaravelResourceResponse> {
    return this.http.delete<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/legal-documents-upload/${ fileId }`)
      .pipe(catchError(response => this.handleError(response)));
  }

  removeUploadedFileCourt(caseId, fileId): Observable<LaravelResourceResponse> {
    return this.http.delete<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/court-documents-upload/${ fileId }`)
      .pipe(catchError(response => this.handleError(response)));
  }

  removeUploadedFile(uuId, fileId): Observable<LaravelResourceResponse> {
    return this.http.delete<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ uuId }/documents-upload/${ fileId }`)
      .pipe(catchError(response => this.handleError(response)));
  }

  getRequiredFiles(uuId): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ uuId }/document-requests`)
      .pipe(catchError(response => this.handleError(response)));
  }

  adminUploadFiles(caseId, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/documents`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  //  todo: add route here
  adminUploadContract(caseId, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/contract-mandates-upload`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  //  todo: add route here
  regenerateContract(caseId, contractId): Observable<LaravelResourceResponse<any>> {
    return this.http.get<LaravelResourceResponse>(
      `${ this.apiUrl }/cases/${ caseId }/contracts/${ contractId }/regenerate`)
      .pipe(catchError(response => this.handleError(response)));
  }

  resignContract(caseId, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/send-contract/resign`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  mergeDocuments(caseId, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/documents/merge`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  mergeLegalDocuments(caseId, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/legal-documents/merge`, data);
  }

  mergeCourtDocuments(caseId, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/court-documents/merge`, data);
  }

  generateDocument(caseId: number, data): Observable<HttpResponse<Blob>> {
    return this.http.post(this.apiUrl + '/cases/' + caseId + '/case-documents/generate', data, {
      observe: 'response', responseType: 'blob',
    }).pipe(catchError(response => this.handleError(response)));
  }

  setDocumentVisibility(caseId: number, data): Observable<LaravelResourceResponse<any>> {
    return this.http.patch<LaravelResourceResponse>(this.apiUrl + '/cases/' + caseId + '/case-documents/documents-visibility',
      data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public generateRightOfAccessLetter(userRole: string, caseCreditorId: number): Observable<HttpResponse<Blob>> {
    return this.http.post(this.apiUrl + '/case-creditors/' + caseCreditorId + '/download-right-of-access',
      {client_role: userRole}, {
        observe: 'response', responseType: 'blob',
      });
  }

  sendAutoDeConcurso(caseUuid, clientRole: 'client' | 'partner'): Observable<LaravelResourceResponse<any>> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseUuid }/legal-documents/auto-de-concurso`,
      {client_role: clientRole})
      .pipe(catchError(response => this.handleError(response)));
  }

  sendAutoDeExoneracion(caseUuid, clientRole: 'client' | 'partner'): Observable<LaravelResourceResponse<any>> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseUuid }/legal-documents/auto-de-exoneracion`,
      {client_role: clientRole})
      .pipe(catchError(response => this.handleError(response)));
  }

  sendSignedContract(caseId: number): Observable<LaravelResourceResponse<any>> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/send-last-signed-contract`, {})
      .pipe(catchError(response => this.handleError(response)));
  }

  resendAdvicePack(caseId, regenerate: boolean): Observable<LaravelResourceResponse<any>> {
    return this.http.post<LaravelResourceResponse>(
      `${ this.apiUrl }/cases/${ caseId }/regenerate-advice-pack`,
      {regenerate})
      .pipe(catchError(response => this.handleError(response)));
  }

  requestDebtCancellationRequestSignature(caseId: number, clientRole: 'client' | 'partner'): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(this.apiUrl + '/cases/' + caseId + '/debt-cancellation-request/request-signature',
      {client_role: clientRole})
      .pipe(catchError(response => this.handleError(response)));
  }

  sendExperianEmail(caseId): Observable<LaravelResourceResponse<any>> {
    return this.http.post<LaravelResourceResponse>(
      `${ this.apiUrl }/cases/${ caseId }/send-experian-cancellation-request`, {})
      .pipe(catchError(response => this.handleError(response)));
  }

  public documentsCount(caseUuid: string, userUuid: string): Observable<LaravelResourceResponse<any>> {
    return this.http.get<LaravelResourceResponse>(
      this.apiUrl + '/client/cases/' + caseUuid + '/users/' + userUuid + '/uploaded-documents-count')
      .pipe(catchError(response => this.handleError(response)));
  }

  public getIsExperianReady(caseId: number): Observable<LaravelResourceResponse<DebtCancellationModel>> {
    return this.http.get<LaravelResourceResponse<DebtCancellationModel>>(
      this.apiUrl + '/cases/' + caseId + '/debt-cancellation-request-status',
    ).pipe(catchError(response => this.handleError(response)));
  }

  public requestProposalSignature(caseId: number, data = ''): Observable<LaravelResourceResponse> {
    const postData = {
      document_type: data,
    };

    return this.http.post<LaravelResourceResponse>(this.apiUrl + '/cases/' + caseId + '/proposal/request-signature', postData)
      .pipe(catchError(response => this.handleError(response)));
  }

  public sendProposalToCreditors(caseId: number): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(
      this.apiUrl + '/cases/' + caseId + '/proposal/send-to-creditors', {type: 'unsecured'})
      .pipe(catchError(response => this.handleError(response)));
  }

  public getDocumentTypes(): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(
      this.apiUrl + '/document-types',
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getCaseDocumentTypeCategories(caseId: number, request: any = {}, relations: Array<string> = []): Observable<LaravelResourceResponse> {
    const params = MainBaseApiService.convertFiltersForRequest({...request, with: relations}, 'get');

    return this.http.get<LaravelResourceResponse>(
      this.apiUrl + '/cases/' + caseId + '/document-type-categories', {params}
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getCaseDocumentTypes(caseId: number): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(
      this.apiUrl + '/cases/' + caseId + '/document-types',
    ).pipe(catchError(response => this.handleError(response)));
  }

  public sendDocument(data: any, caseId: number, docId: number): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(
      this.apiUrl + '/cases/' + caseId + '/send-document/' + docId, {client_role: data.client_role, type: data.type})
      .pipe(catchError(response => this.handleError(response)));
  }

  public sendDebtExonerationSignature(caseId: number, data: {
    channel: 'sms' | 'email'
  }): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(
      this.apiUrl + '/cases/' + caseId + '/debt-exoneration/request-signature', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public debtExonerationDownload(caseId: number, data: {
    'with[0]': string;
    user_id: number;
    app_document_type_slug: string
  }): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/cases/' + caseId + '/get-client-doc', {params: data})
      .pipe(catchError(response => this.handleError(response)));
  }

  public generateExternalLoan(caseId: number): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(this.apiUrl + '/cases/' + caseId + '/generate-loan-request-pdf', {})
      .pipe(catchError(response => this.handleError(response)));
  }

  public previewClientInstallmentList(caseUuid: string): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(
      this.apiUrl + '/cases/' + caseUuid + '/client-installment-list-preview',
    ).pipe(catchError(response => this.handleError(response)));
  }

  public sendClientInstallmentList(caseId: number): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(
      this.apiUrl + '/cases/' + caseId + '/send-client-installment-list', {})
      .pipe(catchError(response => this.handleError(response)));
  }

  public regenerateProposal(caseId: number): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(
      this.apiUrl + '/cases/' + caseId + '/regenerate-proposal', {})
      .pipe(catchError(response => this.handleError(response)));
  }

  public sendProposalToClient(caseId: number): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(
      this.apiUrl + '/cases/' + caseId + '/email-proposal-to-client', {})
      .pipe(catchError(response => this.handleError(response)));
  }
}
