<form *ngIf="form" [formGroup]="form" #ngForm [hidden]="authUser.role?.slug === 'creditor'">
  <div class="row" *ngIf="type === 'case' || type === 'customer_contact' || type === 'affiliate_case' || type === 'packager_non_transferred'">
    <div class="col-8">
      <!-- Start Date -->
      <mat-form-field color="primary" appearance="standard">
        <mat-label>{{ "PAYMENTS.start-date" | translate }}</mat-label>
        <input matInput [matDatepicker]="startDate" formControlName="start_date"
               (dateChange)="dateChanged($event, 'end_date', false)">
        <button *ngIf="form.get('start_date') && form.get('start_date').value" mat-button matSuffix mat-icon-button
                [disabled]="form.get('start_date').disabled" (click)="clearFormControl($event, 'start_date')"
                aria-label="Clear">
          <mat-icon>close</mat-icon>
        </button>
        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate color="primary"></mat-datepicker>
      </mat-form-field>
      <!-- End Date -->
      <mat-form-field color="primary" appearance="standard">
        <mat-label>{{ "PAYMENTS.end-date" | translate }}</mat-label>
        <input matInput [matDatepicker]="endDate" formControlName="end_date"
               (dateChange)="dateChanged($event, 'end_date', true)">
        <button *ngIf="form.get('end_date') && form.get('end_date').value" mat-button matSuffix mat-icon-button
                [disabled]="form.get('end_date').disabled" (click)="clearFormControl($event, 'end_date')"
                aria-label="Clear">
          <mat-icon>close</mat-icon>
        </button>
        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker #endDate color="primary"></mat-datepicker>
      </mat-form-field>
    </div>
    <!-- Search -->
    <div class="col-4">
      <mat-form-field appearance="standard" class="input-full-width">
        <mat-label>{{ 'SHARED.search' | translate }}</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <input matInput formControlName="search" type="text">
        <div *ngIf="form.get('search').errors && (form.get('search').touched || ngForm.submitted)">
          <mat-error [hidden]="!form.get('name').hasError('required')">
            {{ "SHARED.field-required" | translate }}
          </mat-error>
        </div>
      </mat-form-field>
    </div>
  </div>
  <div class="row" *ngIf="type === 'case' || type === 'customer_contact' || type === 'affiliate_case'
  || type === 'packager_non_transferred'">
    <!-- Status Date Type -->
    <div class="pl-5">
      <mat-label style="padding-right: 10px; display: block">
        {{ 'CASES.list.filters.status_date_type.label' | translate }}
      </mat-label>
      <mat-radio-group formControlName="status_date_type" aria-label="Select an option">
        <mat-radio-button value="sign_up">
          {{ "CASES.list.filters.status_date_type.options.sign_up" | translate }}
        </mat-radio-button>
        <mat-radio-button value="change_date"
                          [matTooltip]="'CASES.list.filters.status_date_type.option-labels.sign_up' | translate">
          {{ "CASES.list.filters.status_date_type.options.change_date" | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <!-- Activity Status -->
    <div class="pl-5">
      <mat-label style="padding-right: 10px; display: block"
                 [matTooltip]="'CASES.list.filters.activity_status.label-tooltip' | translate">
        {{ 'CASES.list.filters.activity_status.label' | translate }}
      </mat-label>
      <mat-radio-group formControlName="activity_status" aria-label="Select an option">
        <mat-radio-button value="active">
          {{ "CASES.list.filters.activity_status.options.active" | translate }}
        </mat-radio-button>
        <mat-radio-button value="inactive">
          {{ "CASES.list.filters.activity_status.options.inactive" | translate }}
        </mat-radio-button>
        <mat-radio-button value="all">
          {{ "CASES.list.filters.activity_status.options.all" | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <mat-menu #selectAllMenu="matMenu" xPosition="before" class="selectAllMenu">
      <a mat-menu-item (click)="selectStatuses(true)">
        {{ "CASES.list.filters.status_preset.active" | translate }}
      </a>
      <a mat-menu-item (click)="selectStatuses(false)">
        {{ "CASES.list.filters.status_preset.inactive" | translate }}
      </a>
    </mat-menu>
  </div>
  <!-- Only show on `legal` tab -->
  <div class="row" *ngIf="type === 'legal'">
    <div class="col-8">
      <div class="">
        <mat-label style="padding-right: 10px; display: block"
                   [matTooltip]="'CASES.list.filters.activity_status.label-tooltip' | translate">
          {{ 'CASES.list.filters.activity_status.label' | translate }}
        </mat-label>
        <mat-radio-group formControlName="activity_status" aria-label="Select an option">
          <mat-radio-button value="active">
            {{ "CASES.list.filters.activity_status.options.active" | translate }}
          </mat-radio-button>
          <mat-radio-button value="inactive">
            {{ "CASES.list.filters.activity_status.options.inactive" | translate }}
          </mat-radio-button>
          <mat-radio-button value="all">
            {{ "CASES.list.filters.activity_status.options.all" | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="col-4">
      <mat-form-field appearance="standard" class="input-full-width">
        <mat-label>{{ 'SHARED.search' | translate }}</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <input matInput formControlName="search" type="text">
        <div *ngIf="form.get('search').errors && (form.get('search').touched || ngForm.submitted)">
          <mat-error [hidden]="!form.get('name').hasError('required')">
            {{ "SHARED.field-required" | translate }}
          </mat-error>
        </div>
      </mat-form-field>
    </div>
  </div>
  <!-- Advanced Filters -->
  <div class="filters pb-5 pt-3">
    <div class="advanced-filters">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="d-flex align-items-center justify-content-between" style="width: 100%">
                {{ "PAYMENTS.advanced-filters" | translate }}
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row" *ngIf="type === 'case' || type === 'customer_contact' || type === 'affiliate_case'">
            <!-- Status Categories -->
            <div class=" col-lg">
              <app-input *ngIf="statusCategories?.length" type="select" [formControl]="statusCategoryControl"
                         appearance="standard" [searchable]="true" [multiple]="statusMultipleSelection"
                         [label]="'CASES.single.general.status_editor.status_category' | translate"
                         [selectOptions]="statusCategories" [selectLabel]="'name'" [selectValue]="'id'"
                         class="full-width"
                         (ngModelChange)="updateSelectedStatusCategories($event)"
                         [showClear]="false" [fullWidth]="true">
              </app-input>
            </div>
            <!-- Statuses -->
            <div class=" col-lg">
              <app-input *ngIf="filteredStatusCategories?.length" type="select"
                         [formControlName]="statusFormControlName"
                         appearance="standard" [searchable]="true" [multiple]="statusMultipleSelection"
                         [label]="'CASES.single.general.status_editor.status' | translate"
                         [selectOptions]="filteredStatusCategories" [optGroupProperty]="'statuses'"
                         [optGroupLabel]="'name'"
                         [selectLabel]="'name'" [selectValue]="'id'" class="full-width"
                         [showClear]="false" [fullWidth]="true">
              </app-input>
            </div>
            <!-- Case creditor status -->
            <div class="col-6 col-lg">
              <app-input type="select" formControlName="case_creditor_statuses" appearance="standard" [searchable]="true"
                         [label]="'CONFIG.drip-campaign.list.table-data.statusable_type.case_creditor_status' | translate" [multiple]="true" [selectAll]="true" [fullWidth]="true" [selectAll]="true"
                         [selectOptions]="caseCreditorStatuses" [selectLabel]="authUser.locale === 'es' ? 'name_es' : 'name_en'" [selectValue]="'id'">
              </app-input>
            </div>
            <!-- Document Status -->
            <div class=" col-lg">
              <app-input type="select" formControlName="doc_statuses" appearance="standard"
                         fullWidth="false" [multiple]="true" [showClear]="true" [selectAll]="true"
                         [label]="'DOCUMENTS.document_status' | translate" [searchable]="true"
                         [selectOptions]="docStatusOptions" [selectLabel]="'label'" [selectValue]="'value'">
              </app-input>
            </div>
            <!-- Payment Statuses -->
            <div class=" col-lg">
              <app-input type="select" formControlName="payment_statuses" appearance="standard"
                         fullWidth="false" [multiple]="true" [showClear]="true" [selectAll]="true"
                         [label]="'CASES.single.general.status_editor.payment_status' | translate"
                         [selectOptions]="paymentStatuses" [selectLabel]="'name'" [selectValue]="'id'">
              </app-input>
            </div>
          </div>
          <div class="row">
            <!-- Debt Level Greater Then-->
            <div class=" col-lg" *ngIf="type === 'case'">
              <app-input type="number" formControlName="debt_level_greater_then" [label]="'SHARED.CASE_LIST_FILTERS.debt_level_greater_then' | translate" appearance="standard"
                         [fullWidth]="true">
              </app-input>
            </div>
            <!-- Debt Level Less Then-->
            <div class=" col-lg" *ngIf="type === 'case'">
              <app-input type="number" formControlName="debt_level_less_then" [label]="'SHARED.CASE_LIST_FILTERS.debt_level_less_then' | translate" appearance="standard"
                         [fullWidth]="true">
              </app-input>
            </div>
            <!-- Amount Paid Less Then-->
            <div class=" col-lg" *ngIf="type === 'case' || type === 'customer_contact'">
              <app-input type="number" formControlName="amount_paid_less_then" [label]="'SHARED.CASE_LIST_FILTERS.amount_paid_less_then' | translate" appearance="standard"
                         [fullWidth]="true">
              </app-input>
            </div>
            <!-- Amount Paid Greater Then-->
            <div class=" col-lg" *ngIf="type === 'case' || type === 'customer_contact'">
              <app-input type="number" formControlName="amount_paid_greater_then" [label]="'SHARED.CASE_LIST_FILTERS.amount_paid_greater_then' | translate" appearance="standard"
                         [fullWidth]="true">
              </app-input>
            </div>
            <!-- Creditors -->
            <div class=" col-lg">
              <app-input type="select" formControlName="creditors" appearance="standard"
                         fullWidth="false" [multiple]="true" [showClear]="true" [selectAll]="true"
                         [label]="'CASES.single.creditors.heading' | translate" [searchable]="true"
                         [selectOptions]="creditors" [selectLabel]="'name'" [selectValue]="'id'">
              </app-input>
            </div>
          </div>
          <div class="row">
            <!-- Percentage Reduction Greater Then-->
            <div class=" col-lg" *ngIf="type === 'case' || type === 'customer_contact'">
              <app-input type="number" formControlName="percentage_reduction_greater_then" [label]="'SHARED.CASE_LIST_FILTERS.percent_reduction_greater_then' | translate" appearance="standard"
                         [fullWidth]="true">
              </app-input>
            </div>
            <!-- Percentage Reduction Less Then-->
            <div class=" col-lg" *ngIf="type === 'case' || type === 'customer_contact'">
              <app-input type="number" formControlName="percentage_reduction_less_then" [label]="'SHARED.CASE_LIST_FILTERS.percent_reduction_less_then' | translate" appearance="standard"
                         [fullWidth]="true">
              </app-input>
            </div>
            <div class=" col-lg">
              <app-input type="number" formControlName="dm_percent_accepted_greater" [label]="'CASES.list.filters.dm_percent_accepted.greater' | translate" appearance="standard"
                         [fullWidth]="true">
              </app-input>
            </div>
            <div class=" col-lg">
              <app-input type="number" formControlName="dm_percent_accepted_less" [label]="'CASES.list.filters.dm_percent_accepted.less' | translate" appearance="standard"
                         [fullWidth]="true">
              </app-input>
            </div>
            <div class=" col-lg">
              <app-input type="select" appearance="standard" [label]="'CASES.list.filters.seventy_percent_reduction.label' | translate"
                         formControlName="seventy_percent_reduction" [showClear]="true" [showLabel]="true" [fullWidth]="true"
                         [selectOptions]="hasYesNoOptions" [selectLabel]="'label'" [selectValue]="'value'">
              </app-input>
            </div>
          </div>
          <!-- Department Users -->
          <div class="row">
            <ng-container *ngFor="let departmentCategory of departmentCategories">
              <ng-container *ngFor="let department of departmentCategory.departments">
                <div class=" col-lg" *ngIf="department.active">
                  <app-input type="select" *ngIf="form" [formControlName]="department.type" appearance="standard"
                             [label]="department.name" multiple [fullWidth]="true" searchable="true"
                             [selectOptions]="department.users" [selectLabel]="'name'" [selectValue]="'id'">
                  </app-input>
                </div>
              </ng-container>
            </ng-container>
            <!--Packagers Statuses-->
            <!-- <div class="col-lg-3" *ngIf="!authUser.packager.master">
              <app-input type="select" appearance="standard" [label]="'CASES.packager-status' | translate"
                         formControlName="packager_statuses" [showClear]="false" [showLabel]="true" [fullWidth]="true"
                         [selectOptions]="packagerStatuses" [multiple]="true" [selectLabel]="['name_en']"
                         [selectValue]="'id'">
              </app-input>
            </div> -->
            <div class=" col-lg">
              <app-input type="select" appearance="standard" [label]="'70% Micro Lenders'"
                         formControlName="seventy_percent_microlenders" [showClear]="true" [showLabel]="true" [fullWidth]="true"
                         [selectOptions]="hasYesNoOptions" [selectLabel]="'label'" [selectValue]="'value'">
              </app-input>
            </div>
            <div class=" col-lg">
              <app-input type="select" appearance="standard" [label]="'CASES.list.filters.has_microlenders.label' | translate"
                         formControlName="has_microlenders" [showClear]="true" [showLabel]="true" [fullWidth]="true"
                         [selectOptions]="hasYesNoOptions" [selectLabel]="'label'" [selectValue]="'value'">
              </app-input>
            </div>
          </div>
          <div class="row">
            <!-- Affiliates -->
            <div class=" col-lg">
              <app-input type="select" formControlName="affiliates" appearance="standard"
                         fullWidth="false" [multiple]="true" [showClear]="true" [selectAll]="true"
                         [label]="'CASES.affiliates' | translate"
                         [selectOptions]="affiliates" [selectLabel]="'name'" [selectValue]="'id'" [searchable]="true">
              </app-input>
            </div>
            <!-- Has Property -->
            <div class="col-lg">
              <app-input type="select" appearance="standard" [label]="'CASES.list.filters.has_property.label' | translate"
                         formControlName="has_property" [showClear]="true" [showLabel]="true" [fullWidth]="true"
                         [selectOptions]="hasPropertyOptions" [selectLabel]="'label'" [selectValue]="'value'">
              </app-input>
            </div>
            <!-- Days since last neg note -->
            <div class="col-lg">
              <app-input type="number" formControlName="days_since_last_neg_note" [label]="'SHARED.CASE_LIST_FILTERS.days_since_last_neg_note' | translate" appearance="standard"
                         [fullWidth]="true">
              </app-input>
            </div>
            <!-- Has Sold Debt -->
            <div class="col-lg">
              <app-input type="select" appearance="standard" [label]="'CASES.list.filters.has_sold_debt.label' | translate"
                         formControlName="has_sold_debt" [showClear]="true" [showLabel]="true" [fullWidth]="true"
                         [selectOptions]="hasSoldDebtOptions" [selectLabel]="'label'" [selectValue]="'value'">
              </app-input>
            </div>
            <div class="col-lg">
              <app-input type="select" appearance="standard" [label]="'CASES.list.filters.has_settlement_funds.label' | translate"
                         formControlName="has_settlement_funds" [showClear]="true" [showLabel]="true" [fullWidth]="true"
                         [selectOptions]="hasSettlementFundsOptions" [selectLabel]="'label'" [selectValue]="'value'">
              </app-input>
            </div>
          </div>
          <!-- <div class="row">
          </div> -->
          <!-- Case Entities -->
          <div class="row" *ngIf="type === 'legal'">
            <!-- Notaries -->
            <div class="col-6 col-lg">
              <app-input type="select" formControlName="days_no_contact_channels" appearance="standard"
                         fullWidth="false" [multiple]="true" [showClear]="true" [selectAll]="true"
                         [label]="'LEGAL.notary' | translate"
                         [selectOptions]="notaries" [selectLabel]="'name'" [selectValue]="'id'">
              </app-input>
            </div>
            <!-- Administrators -->
            <div class="col-6 col-lg">
              <app-input type="select" formControlName="administrators" appearance="standard"
                         fullWidth="false" [multiple]="true" [showClear]="true" [selectAll]="true"
                         [label]="'LEGAL.administrator' | translate"
                         [selectOptions]="administrators" [selectLabel]="'name'" [selectValue]="'id'">
              </app-input>
            </div>
            <!-- Courts -->
            <div class="col-6 col-lg">
              <app-input type="select" formControlName="courts" appearance="standard"
                         fullWidth="false" [multiple]="true" [showClear]="true" [selectAll]="true"
                         [label]="'LEGAL.court' | translate"
                         [selectOptions]="courts" [selectLabel]="'name'" [selectValue]="'id'">
              </app-input>
            </div>
            <!-- Solicitors -->
            <div class="col-6 col-lg">
              <app-input type="select" formControlName="solicitors" appearance="standard"
                         fullWidth="false" [multiple]="true" [showClear]="true" [selectAll]="true"
                         [label]="'LEGAL.solicitor' | translate"
                         [selectOptions]="solicitors" [selectLabel]="'name'" [selectValue]="'id'">
              </app-input>
            </div>
          </div>
          <div class="row" *ngIf="type === 'legal'">
            <div class="col-6 col-lg">
              <mat-form-field color="primary" appearance="standard" class="input-full-width">
                <mat-label>{{'ADDRESS.city' | translate}}</mat-label>
                <input type="text" matInput formControlName="city">
              </mat-form-field>
            </div>
            <div class="col-6 col-lg">
              <mat-form-field color="primary" appearance="standard" class="input-full-width">
                <mat-label>{{ 'ADDRESS.region' | translate }}</mat-label>
                <input type="text" matInput formControlName="region">
              </mat-form-field>
            </div>
            <div class="col-6 col-lg">
              <mat-form-field color="primary" appearance="standard" class="input-full-width">
                <mat-label>{{'CASES.list.filters.notary_appointed.label' | translate}}</mat-label>
                <mat-select formControlName="notary_appointed">
                  <mat-option [value]="1">{{'SHARED.yes' | translate}}</mat-option>
                  <mat-option [value]="0">{{'SHARED.no' | translate}}</mat-option>
                </mat-select>
                <button *ngIf="form.get('notary_appointed') &&
                  (form.get('notary_appointed').value === 0 || form.get('notary_appointed').value === 1)"
                        mat-button matSuffix mat-icon-button
                        [disabled]="form.get('notary_appointed').disabled" aria-label="Clear"
                        (click)="clearFormControl($event, 'notary_appointed')">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </div>
          <!-- <div class="row" *ngIf="type === 'case'">
            <div class="col-6 col-md-4 col-lg-3 col-xl-3" *ngIf="authUser.packager.master">
              <mat-slide-toggle formControlName="has_redsys_has_no_cashflows_token" color="primary" class="ml-5">
                {{ 'CASES.list.filters.has_redsys_has_no_cashflows_token.label' | translate }}
              </mat-slide-toggle>
            </div>
          </div> -->
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <div *ngIf="type === 'case'" class="row">
    <div class="col-4">
      <button mat-raised-button color="primary" style="margin-bottom:10px;" (click)="this.parentNewCase.emit()">
        + {{ "CASES.add-new" | translate }}
      </button>
    </div>
    <div class="col-8 d-flex justify-content-end">
      <div class="text-right">
      <mat-slide-toggle formControlName="settlement_balance_greater_than_cred_debt" color="primary" class="ml-3" (ngModelChange)="casesWithBalanceGreaterThenDebt($event)">
        Balance > Lowest Debt
      </mat-slide-toggle>
    <mat-radio-group formControlName="product" class="ml-3">
      <mat-radio-button value="lso">LSO</mat-radio-button>
      <mat-radio-button value="dmp">DMP</mat-radio-button>
      <mat-radio-button value="unified">FDS</mat-radio-button>
      <mat-radio-button value="ds">DS</mat-radio-button>
      <mat-radio-button value="all">All</mat-radio-button>
    </mat-radio-group>
    <!-- <div class="col-6" *ngIf="filterType !== 'legal'"> -->
      <button mat-raised-button color="primary" class="ml-2" (click)="this.parentFilter.emit()">
        {{ 'SHARED.apply_filters' | translate }}
      </button>
      <button mat-stroked-button color="primary" class="ml-2" (click)="this.parentClearFilters.emit()">
        {{"SHARED.reset-filters" | translate}}
      </button>
      <!-- </div> -->
      </div>
    </div>
  </div>
</form>
<form *ngIf="form" [formGroup]="form" #ngForm [hidden]="authUser.role?.slug !== 'creditor'">
  <div class="row" *ngIf="type === 'case' || type === 'customer_contact' || type === 'affiliate_case' || type === 'packager_non_transferred'">
    <div class="col-4">
      <!-- Search -->
        <mat-form-field appearance="standard" class="input-full-width">
          <mat-label>{{ 'SHARED.search' | translate }}</mat-label>
          <mat-icon matPrefix>search</mat-icon>
          <input matInput formControlName="search" type="text">
          <div *ngIf="form.get('search').errors && (form.get('search').touched || ngForm.submitted)">
            <mat-error [hidden]="!form.get('name').hasError('required')">
              {{ "SHARED.field-required" | translate }}
            </mat-error>
          </div>
        </mat-form-field>
    </div>
    <div class="col-8">
      <div>
        <button mat-raised-button color="primary" class="ml-2" (click)="this.parentFilter.emit()">
          {{ 'SHARED.apply_filters' | translate }}
        </button>
        <button mat-stroked-button color="primary" class="ml-2" (click)="this.parentClearFilters.emit()">
          {{"SHARED.reset-filters" | translate}}
        </button>
        <!-- </div> -->
      </div>
    </div>
  </div>
</form>
