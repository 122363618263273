<div *ngIf="isLoading || serverResponse" class="card mb-3">
  <div class="card-body">
    <div class="row">
      <div [hidden]="!isLoading" class="col-12">
        <mat-spinner class="m-0 m-auto"></mat-spinner>
      </div>
      <div *ngIf="serverResponse" class="col-12">
        <app-server-response [response]="serverResponse"></app-server-response>
      </div>
    </div>
  </div>
</div>

<div *ngIf="financialOverview" class="row outcome-wrap">
  <div *ngFor="let product of products" class="col-3">
    <div class="card shadow">
      <div class="card-header text-center selectable"
           [class.selected]="selectedProductControl.value === product.id">
        <h2 class="m-0">{{ product.name }}</h2>
      </div>
      <div class="card-body d-flex flex-column" style="min-height: 900px">
        <!-- Dynamic Description -->
        <div class="dynamic-description">
          <!-- LSO General Info -->
          <div *ngIf="product.group_slug === 'lso'">
            <p *ngIf="product.slug === 'lso-te' || product.slug === 'lso-lq'">
              {{ 'CASES.editor.outcome.product_summary.expected_term_label' | translate }}:
              {{ 'SHARED.average' | translate }} 6 {{ 'SHARED.months' | translate }}
            </p>
            <p *ngIf="product.slug === 'lso' || product.slug === 'lso2'">
              {{ 'CASES.editor.outcome.product_summary.expected_term_label' | translate }}:
              {{ 'SHARED.average' | translate }} 60 {{'SHARED.months' | translate}}
            </p>
            <p *ngIf="product.slug === 'lso-te' || product.slug === 'lso-lq'">
              {{ 'CASES.financial_overview.table.disposable_income' | translate }}:
              {{(financialOverview?.income - financialOverview?.expenses) | number:'1.2-2'}}€
            </p>
            <div class="d-flex" *ngIf="product.slug === 'lso-te' || product.slug === 'lso-lq'">
              <p>
                {{ 'CASES.financial_overview.table.unsecured_debt' | translate }}:
                {{ financialOverview?.unsecured_debt | number:'1.2-2' }}€
              </p>
            </div>
            <div class="d-flex" *ngIf="product.slug === 'lso-te' || product.slug === 'lso-lq'">
              <p>
                {{ 'CASES.editor.outcome.product_summary.has_public_debt' | translate }}:
                {{ case?.public_debts?.length ? ('SHARED.yes' | translate) : ('SHARED.no' | translate) }}
              </p>
            </div>
            <div class="d-flex" *ngIf="product.slug === 'lso-te' || product.slug === 'lso-lq'">
              <p class="">
                {{ 'CASES.editor.outcome.product_summary.total_assets_less_than_unsecured_debut' | translate }}:
                {{ (financialOverview.assets < financialOverview.unsecured_debt) ?
                ('SHARED.yes' | translate) :
                ('SHARED.no' | translate) }}
              </p>
            </div>
            <div class="d-flex" *ngIf="potentialAzcarate.length">
              <p class="pr-2" style="text-decoration: underline">
                {{ 'CASES.editor.outcome.product_summary.potential_azcarate_list' | translate }}:
              </p>
              <p *ngFor="let azcarateCreditor of potentialAzcarate; let i = index" class="pr-2"
                 style="text-decoration: underline">
                {{ azcarateCreditor.name }}{{ i + 1 < potentialAzcarate.length ? ', ' : ''}}
              </p>
            </div>
          </div>
          <!-- DM General Info -->
          <div *ngIf="product.group_slug === 'dmp'">
            <div class="d-flex">
              <p>
                {{ 'CASES.editor.outcome.product_summary.expected_term_label' | translate }}:
                {{ expectedTerm | number:'1.2-2'}} {{ 'SHARED.months' | translate }}
              </p>
            </div>
            <div class="d-flex">
              <p>
                {{ 'CASES.financial_overview.table.disposable_income' | translate }}:
                {{ (financialOverview?.income - financialOverview?.expenses) | number:'1.2-2' }}€
              </p>
            </div>
            <div class="d-flex">
              <p>
                {{ 'CASES.financial_overview.table.unsecured_debt' | translate }}:
                {{ financialOverview?.unsecured_debt | number:'1.2-2' }}€
              </p>
            </div>
            <div class="d-flex">
              <p>
                {{ 'CASES.editor.outcome.product_summary.unsecured_creditor_count' | translate }}:
                {{ unsecuredCreditorsCount }}
              </p>
            </div>
            <div class="d-flex" *ngIf="potentialAzcarate.length">
              <p class="pr-2" style="text-decoration: underline">
                {{ 'CASES.editor.outcome.product_summary.potential_azcarate_list' | translate }}:
              </p>
              <p *ngFor="let azcarateCreditor of potentialAzcarate; let i = index" class="pr-2"
                 style="text-decoration: underline">
                {{ azcarateCreditor.name }}{{ i + 1 < potentialAzcarate.length ? ', ' : ''}}
              </p>
            </div>
            <p style="padding-bottom: 40px"></p>
          </div>
          <!-- Debt Settlement Plan -->
          <div *ngIf="product.group_slug === 'ds'">
            <!-- <div class="d-flex">
              <p>
                {{ 'CASES.financial_overview.table.unsecured_debt' | translate }}:
                {{ financialOverview?.unsecured_debt | number:'1.2-2' }}€
              </p>
            </div> -->
            <div class="d-flex" *ngFor="let resumen of resumenDelPlan">
              <p>
                {{ resumen.title }}:
                {{ resumen.value | currency }}
              </p>
            </div>
            <!-- <div class="d-flex">
              <p>
                {{ 'CASES.editor.outcome.product_summary.expected_term_label' | translate }}:
                {{ dsTerms[0].maxTerms }} {{ 'SHARED.months' | translate }}
              </p>
            </div> -->
            <div class="d-flex">
              <p>
                {{ 'CASES.single.target_monthly_payment' | translate }}:
                {{ (financialOverview?.target_monthly_payment) | currency }}
              </p>
            </div>
            <div class="d-flex">
              <p>
                {{ 'CASES.editor.outcome.product_summary.expected_term_label' | translate }}:
                {{dsExpectedTerm}} {{ 'SHARED.months' | translate }}
              </p>
            </div>
            <div class="d-flex" *ngIf="potentialAzcarate.length">
              <p class="pr-2" style="text-decoration: underline">
                {{ 'CASES.editor.outcome.product_summary.potential_azcarate_list' | translate }}:
              </p>
              <p *ngFor="let azcarateCreditor of potentialAzcarate; let i = index" class="pr-2"
                 style="text-decoration: underline">
                {{ azcarateCreditor.name }}{{ i + 1 < potentialAzcarate.length ? ', ' : ''}}
              </p>
            </div>
            <div [innerHtml]="product.description.replace('%%target%%', financialOverview.target_monthly_payment).replace('%%term%%', dsExpectedTerm)"></div>
          </div>
        </div>
        <div *ngIf="product.slug === 'unified'">
          <p>
            {{'CASES.editor.outcome.existing_debt' | translate}}:
            {{(financialOverview?.unsecured_debt) | number:'1.2-2'}}€
          </p>
          <p>
            {{'CASES.editor.outcome.renegotiated_debt' | translate}}:
            {{renegotiatedDebt | number:'1.2-2'}}€
          </p>
          <p>
            {{'CASES.editor.outcome.monthly_repayable' | translate}}:
            {{monthlyRepayable | number:'1.2-2'}}€
          </p>
          <div [innerHtml]="product.description"></div>
        </div>
        <mat-divider class="mt-2 mb-5"></mat-divider>
        <!-- <div [innerHtml]="product.description" *ngIf="product.group_slug !== 'cajaplus' || product.group_slug !== 'ds'"></div> -->
        <div *ngIf="product.group_slug === 'cajaplus'">
          <p [ngStyle]="{'font-size': product.group_slug === 'cajaplus' ? '20px' : ''}">
            {{'CASES.editor.outcome.cajaplus_text_one' | translate}} <span
            class="disposableIncome"><b>{{ legalFees | currency }}</b></span> {{'CASES.editor.outcome.cajaplus_text_for' | translate}}
            <span class="numberOfMonths">
              <b>{{getRepaymentPlan()}}</b>
            </span> {{'CASES.editor.outcome.cajaplus_text_meses' | translate}}
            <br><br>
            {{'CASES.editor.outcome.cajaplus_text_two' | translate}}
            <br><br>
            {{'CASES.editor.outcome.cajaplus_text_three' | translate}}
            <br><br>
            {{'CASES.editor.outcome.cajaplus_text_four' | translate}}
            <br><br>
            {{'CASES.editor.outcome.cajaplus_text_five' | translate}}
          </p>
        </div>
        <!-- Select Product -->
        <div *ngIf="isProductSelectable(product)" class="d-flex justify-content-center">
          <button mat-button mat-raised-button color="primary" (click)="selectOutcome(product)" [disabled]="case.locked_no_products == true">
            {{ 'CASES.editor.outcome.select_outcome' | translate }}
          </button>
        </div>
        <!--Cajaplus not selectable error text-->
        <div *ngIf="!isCajaplusSelectable && product.group_slug === 'cajaplus'">
          <p
            class="cajaplus-error">{{'CASES.editor.outcome.product_cant_be_selected' | translate}} {{minDisposableIncome}}
            .</p>
        </div>
        <!-- Is Selected -->
        <div *ngIf="selectedProductControl.value === product.id && case.product_assigned_at" class="text-center"
             style="margin-top: auto">
          <mat-icon style="font-size: 150px; width: auto; height: auto" class="mat-icon-size" color="primary">
            check_circle
          </mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>
