<div class="container">
    <div class="row">
        <div>
            <div *ngIf="isLoading" class="col-12 pt-5 text-center">
                <mat-spinner class="m-0 m-auto"></mat-spinner>
            </div>
        </div>
        <div class="col-12" *ngIf="stats && !isLoading">
            <div layout="row" layout-xs="column">
                <h1>Hello, {{ name }}</h1>
                <mat-tab-group *ngIf="!errorMessage">
                    <!-- <mat-tab label="Devuelve"> Content 3 </mat-tab>
                    <mat-tab label="NovoMoney"> Content 3 </mat-tab> -->
                    <mat-tab label="Unifye">
                        <div *ngIf="stats" class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <table class="table mat-table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Today</th>
                                                    <th>Month</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Applicants</td>
                                                    <td>{{ stats[0].signups.today }}</td>
                                                    <td>{{ stats[0].signups.month }}</td>
                                                </tr>
                                                <tr>
                                                    <td>New Clients</td>
                                                    <td>{{ stats[0].new_client.today }}</td>
                                                    <td>{{ stats[0].new_client.month }}</td>
                                                </tr>
                                                <tr>
                                                    <td>Contract Signed</td>
                                                    <td>{{ stats[0].contract_signed.today }}</td>
                                                    <td>{{ stats[0].contract_signed.month }}</td>
                                                </tr>
                                                <tr>
                                                    <td>DM Accepted</td>
                                                    <td>{{ stats[0].dm_accepted.today }}</td>
                                                    <td>{{ stats[0].dm_accepted.month }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                
                                <h5 class="card-title">Pago Fraccionado</h5>
                                <div class="card-text">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Today</th>
                                                <th>Week</th>
                                                <th>Month</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let stat of stats">
                                                <td>{{ stat.pago_fraccionado.today | currency }}</td>
                                                <td>{{ stat.pago_fraccionado.week | currency }}</td>
                                                <td>{{ stat.pago_fraccionado.month | currency }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <h5 class="card-title">Payments Received</h5>
                                <div class="card-text">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Today</th>
                                                <th>Week</th>
                                                <th>Month</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let stat of stats">
                                                <td>{{ stat.payments_received.today | currency }}</td>
                                                <td>{{ stat.payments_received.week | currency }}</td>
                                                <td>{{ stat.payments_received.month | currency }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="DeudaFix" [disabled]="true">
                        <div *ngIf="stats" class="card mt-3">
                            <div class="card-body">
                                <h5 class="card-title">Pago Fraccionado</h5>
                                <div class="card-text">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Today</th>
                                                <th>Month</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let stat of stats">
                                                <td>{{ stat.pago_fraccionado.today | currency }}</td>
                                                <td>{{ stat.pago_fraccionado.month | currency }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
            <!-- Error card -->
            <div *ngIf="errorMessage" class="card alert-danger">
                <div class="card-body">
                    <h5 class="card-title">{{ errorTitle }}</h5>
                    <p class="card-text">{{ errorMessage }}</p>
                </div>
            </div>
        </div>
    </div>
</div>