<div class="collapse navbar-collapse client-nav-desktop" id="navbarNavDropdown"
     style="position: absolute;" [ngStyle]="{'right': authUser?.role?.slug === 'affiliate' ? '197px' : '100px'}">
  <!-- Affiliate NAVIGATION -->
  <ul *ngIf="authUser && !authUser?.role?.is_staff && authUser?.role?.slug === 'affiliate'" class="navbar-nav mr-auto">
    <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <a routerLink="" title="Dashboard" class="nav-link">
        <span class="nav-link-text">{{ "NAVBAR.dashboard" | translate }}</span>
      </a>
    </li>
    <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <a [routerLink]="['affiliate', 'cases']" title="Affiliate Cases" class="nav-link">
        <span class="nav-link-text">{{ "NAVBAR.cases" | translate }}</span>
      </a>
    </li>
    <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <a [routerLink]="['affiliate', 'leads', 'create']" title="Create lead" class="nav-link">
        <span class="nav-link-text">{{ "NAVBAR.create-lead" | translate }}</span>
      </a>
    </li>
  </ul>
  <!-- Distribution Provider NAVIGATION -->
  <ul *ngIf="authUser && !authUser?.role?.is_staff && authUser?.role?.slug === 'distribution-provider'"
      class="navbar-nav mr-auto">
    <!--<li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">-->
    <!--  <a routerLink="" title="Dashboard" class="nav-link">-->
    <!--    <span class="nav-link-text">{{ "NAVBAR.dashboard" | translate }}</span>-->
    <!--  </a>-->
    <!--</li>-->
    <!-- Distribution -->
   <!-- <li routerLinkActive="active" class="nav-item">
      <a [routerLink]="['/distribution-provider', 'batches']" title="Distribution" class="nav-link">
        <span class="nav-link-text">{{ "NAVBAR.distribution" | translate }}</span>
      </a>
    </li>-->
  </ul>
</div>
<div *ngIf="authUser && !authUser?.role?.is_staff && authUser?.role?.slug === 'creditor'" class="collapse navbar-collapse client-nav-desktop" id="navbarNavDropdown">
      <ul class="navbar-nav mr-auto">
        <!-- <li class="nav-item" routerLinkActive="active" style="white-space: nowrap;" [routerLinkActiveOptions]="{ exact: true }">
          <a [routerLink]="['cases']" class="nav-link">
            <span class="nav-link-text">{{ "NAVBAR.payment-plans" | translate }}</span>
          </a>
        </li> -->
        <li class="nav-item" routerLinkActive="active" style="white-space: nowrap;" [routerLinkActiveOptions]="{ exact: true }">
          <a [routerLink]="['cases', 'settlement']" class="nav-link">
            <span class="nav-link-text">{{ "NAVBAR.final-settlement" | translate }}</span>
          </a>
        </li>
        <li class="nav-item" routerLinkActive="active" style="white-space: nowrap;" [routerLinkActiveOptions]="{ exact: true }">
          <a [routerLink]="['cases', 'updatebalance']" class="nav-link">
            <span class="nav-link-text">{{ "NAVBAR.update-balance" | translate }}</span>
          </a>
        </li>
        <li class="nav-item" routerLinkActive="active" style="white-space: nowrap; cursor: pointer;">
          <a (click)="logout()" class="nav-link">
            <span class="nav-link-text">{{ "NAVBAR.logout" | translate }}</span>
          </a>
        </li>
      </ul>
  </div>
