import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { MiscConfigService } from './misc-config.service';
import { AppSelectOption } from '../../../../../../_base-shared/contracts/common.interface';

@Component({
  selector:    'app-misc-config',
  templateUrl: './misc-config.component.html',
  styleUrls:   ['./misc-config.component.scss'],
})
export class MiscConfigComponent implements OnInit {
  public form: UntypedFormGroup;
  public formReady    = false;
  public isSubmitting = 0;
  public amountPaid: Array<AppSelectOption> = [
    {label: '250', value: '250'},
    {label: '375', value: '375'},
    {label: '500', value: '500'},
    {label: '750', value: '750'},
  ]
  public billing_restricted: number = 0;

  constructor(private fb: UntypedFormBuilder,
              private configService: MiscConfigService,
              private toast: ToastrService,
              public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.getConfigData();
  }

  private getConfigData(): void {
    this.configService.getConfigData().subscribe(next => {
      this.buildForm(next.data);
    });
  }

  public submit(): void {
    this.isSubmitting++;
    this.configService.index(this.form.value)
      .pipe(finalize(() => this.isSubmitting--))
      .subscribe(
        res => {
          this.toast.success(this.translate.instant('CONFIG.system-event.config-saved'));
        },
        error => {
          this.toast.error(this.translate.instant('CONFIG.system-event.config-saved-error'));
        });
  }

  private buildForm(data): void {
    const teamMonthlyTarget       = data.find(obj => obj.key === 'team_monthly_target_fds');
    // const salesTarget = data.find(obj => obj.key === 'sales_target');
    const individualMonthlyTarget   = data.find(obj => obj.key === 'individual_monthly_target_fds');
    const aprRate                   = data.find(obj => obj.key === 'apr_rate');
    const debtReductionFee          = data.find(obj => obj.key === 'debt_reduction_fee');
    const percentageReductionBad    = data.find(obj => obj.key === 'percentage_reduction_bad');
    const percentageReductionDecent = data.find(obj => obj.key === 'percentage_reduction_decent');
    const percentageReductionGood   = data.find(obj => obj.key === 'percentage_reduction_good');

    const fixedDistributionFee      = data.find(obj => obj.key === 'fixed_distribution_fee');
    const percentageDistributionFee = data.find(obj => obj.key === 'percentage_distribution_fee');
    const mandatesAmountPaid        = data.find(obj => obj.key === 'mandates_amount_paid_threshold');

    const minimumOfferToCreditors   = data.find(obj => obj.key === 'minimum_offer_to_creditors');
    const maximumPercentageOffer    = data.find(obj => obj.key === 'maximum_percentage_offer_to_creditors');

    const negotiation_fee_percentage = data.find(obj => obj.key === 'negotiation_fee_percentage');
    const default_iva_rate           = data.find(obj => obj.key === 'default_iva_rate');
    // DS Specific Options
    const ds_reduction_percent       = data.find(obj => obj.key === 'ds_reduction_percent');
    const ds_default_fee_amount      = data.find(obj => obj.key === 'ds_default_fee_amount');
    const ds_default_fds_reduction   = data.find(obj => obj.key === 'ds_default_fds_reduction');
    // default expense values
    const expense_default_ibi        = data.find(obj => obj.key === 'expense_default_ibi');
    const expense_default_water      = data.find(obj => obj.key === 'expense_default_water');
    const expense_default_power      = data.find(obj => obj.key === 'expense_default_power');
    const expense_default_fuel       = data.find(obj => obj.key === 'expense_default_fuel');
    const expense_default_insurance  = data.find(obj => obj.key === 'expense_default_insurance');
    const expense_default_bus        = data.find(obj => obj.key === 'expense_default_bus');
    const expense_default_phone      = data.find(obj => obj.key === 'expense_default_phone');
    const expense_default_groceries  = data.find(obj => obj.key === 'expense_default_groceries');

    const lockout_default_percentage = data.find(obj => obj.key === 'lockout_default_percentage');
    const default_monthly_term       = data.find(obj => obj.key === 'default_monthly_term');

    this.form = this.fb.group({
      team_monthly_target_fds:        teamMonthlyTarget ? teamMonthlyTarget.value : '',
      individual_monthly_target_fds:  individualMonthlyTarget ? individualMonthlyTarget.value : '',
      apr_rate:                       aprRate ? aprRate.value : '',
      debt_reduction_fee:             debtReductionFee ? debtReductionFee.value : '',
      percentage_reduction_bad:       percentageReductionBad ? percentageReductionBad.value : '',
      percentage_reduction_decent:    percentageReductionDecent ? percentageReductionDecent.value : '',
      percentage_reduction_good:      percentageReductionGood ? percentageReductionGood.value : '',
      fixed_distribution_fee:         fixedDistributionFee ? fixedDistributionFee.value : '',
      percentage_distribution_fee:    percentageDistributionFee ? percentageDistributionFee.value : '',
      mandates_amount_paid_threshold: mandatesAmountPaid ? mandatesAmountPaid.value : '',
      minimum_offer_to_creditors:     minimumOfferToCreditors ? minimumOfferToCreditors.value : '',
      maximum_percentage_offer_to_creditors: maximumPercentageOffer ? maximumPercentageOffer.value : '',
      negotiation_fee_percentage:     negotiation_fee_percentage ? negotiation_fee_percentage.value : '',
      ds_reduction_percent:           ds_reduction_percent ? ds_reduction_percent.value : '',
      ds_default_fee_amount:          ds_default_fee_amount ? ds_default_fee_amount.value : '',
      ds_default_fds_reduction:       ds_default_fds_reduction ? ds_default_fds_reduction.value : '',
      default_iva_rate:               default_iva_rate ? default_iva_rate.value : '',
      expense_default_ibi:            expense_default_ibi ? expense_default_ibi.value : '',
      expense_default_water:          expense_default_water ? expense_default_water.value : '',
      expense_default_power:          expense_default_power ? expense_default_power.value : '',
      expense_default_fuel:           expense_default_fuel ? expense_default_fuel.value : '',
      expense_default_insurance:      expense_default_insurance ? expense_default_insurance.value : '',
      expense_default_bus:            expense_default_bus ? expense_default_bus.value : '',
      expense_default_phone:          expense_default_phone ? expense_default_phone.value : '',
      expense_default_groceries:      expense_default_groceries ? expense_default_groceries.value : '',
      lockout_default_percentage:     [lockout_default_percentage ? lockout_default_percentage.value : '', [Validators.required, Validators.min(1), Validators.max(100)]],
      default_monthly_term:           [default_monthly_term ? default_monthly_term.value : '', [Validators.required, Validators.min(1)]],
    });

    this.formReady = true;
  }
}
